/* Removed import here to preconnect to google fonts in index.html */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap'); */

@font-face {
  font-family: 'visby_regular';
  src: url('../fonts/visbycf-regular-webfont.woff2') format('woff2'),
    url('../fonts/visbycf-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'visby_cfbold';
  src: url('../fonts/visbycf-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/visbycf-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'visby_bold';
  src: url('../fonts/visbycf-bold-webfont.woff2') format('woff2'),
    url('../fonts/visbycf-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'visby_medium';
  src: url('../fonts/visbycf-medium-webfont.woff2') format('woff2'),
    url('../fonts/visbycf-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --bs-font-sans-serif: 'visby_cfbold', sans-serif;
  --bs-font-menu: 'visby_bold', sans-serif;
  --bs-font-medium: 'visby_medium', sans-serif;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: 'Poppins', sans-serif;
  --bs-body-font-size: 0.9rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #fff;
  --bs-body-bg: #171630;
  --bs-primary: #ffd956;
  --bs-primary-rgb: 255, 217, 86;
  --bs-secondary: #171630;
  --bs-secondary-rgb: 23, 22, 48;
  --shadow-rgba: rgba(0, 0, 0, 0.15);
  --bs-white: #fff;
  --bs-font-regular: 'visby_regular', sans-serif;
  --border-radius: 0.625rem;
}

body {
  background-color: var(--bs-body-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--bs-body-color);
}
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.btn,
.btn:hover,
.btn:focus,
.btn:active {
  border-radius: var(--border-radius);
  outline: none !important;
  box-shadow: none !important;
  font-family: var(--bs-font-medium) !important;
}
.btn-primary,
.btn {
  font-size: 0.875rem;
  padding: 0.75rem 1.125rem;
  line-height: 1;
}
.btn-outline-light {
  border: solid 0.0625rem #c1c1c1 !important;
}
.btn-primary,
.btn-primary:focus,
.btn-primary:hover,
.btn-outline-light:hover,
.division-tabs .nav-pills .nav-link.active {
  border: solid 0.0625rem var(--bs-secondary-rgb);
  box-shadow: none;
  color: var(--bs-secondary);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  text-decoration: none;
}

.btn-primary:hover {
  background: rgb(255, 251, 217);
}

.btn-outline-primary {
  background-color: transparent !important;
  color: #fff;
  margin-bottom: 0.9375rem;
  font-size: 0.875rem;
  padding: 0.625rem 1.375rem;
  border-radius: 2.5rem;
  margin-right: 0.625rem;
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.btn-outline-primary:hover {
  border: solid 0.0625rem var(--bs-primary);
  box-shadow: none;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  color: var(--bs-secondary);
  padding: 0.625rem 1.375rem;
  border-radius: 2.5rem;
}
.btn-outline-light:hover {
  border: solid 0.0625rem var(--bs-secondary-rgb);
  background: none;
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: var(--bs-white);
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-radius: var(--border-radius);
}
.select-radio .btn-group,
.select-radio .btn-group-vertical {
  display: inline-block;
}
h1,
h2,
h3,
h4,
h5,
h6,
h7,
.form-label {
  font-family: var(--bs-font-sans-serif);
  letter-spacing: normal;
  line-height: 1;
}

.form-label {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1;
}

h4 {
  font-family: var(--bs-body-font-family);
}

.align-items-center {
  align-items: center !important;
}
b,
strong {
  font-weight: 800;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
::placeholder {
  color: rgb(210, 210, 210) !important;
}
:-ms-input-placeholder {
  color: rgb(210, 210, 210) !important;
}
::-ms-input-placeholder {
  color: rgb(210, 210, 210) !important;
}
.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.form-check-input:checked[type='checkbox'] {
  background-image: url(../images/checkbox.svg);
  background-size: 110%;
}
.check-input .form-check-input {
  width: 1.2em;
  height: 1.2em;
  background-color: #000;
  border: 0.0625rem solid #fff;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.check-input .form-check-input:checked {
  background-color: #000;
  border-color: #000;
  border: 0.0625rem solid var(--bs-primary);
}
.form-check-input {
  width: 1.2em;
  height: 1.2em;
  background-color: rgba(255, 255, 255, 0.1);
  border: 0.0625rem solid #fff;
  box-shadow: none !important;
}
.form-control,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border: 0.0625rem solid rgba(255, 255, 255, 0.1);
  font-family: var(--bs-body-font-family);
  border-radius: var(--border-radius);
  resize: none;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
}
.form-control:hover {
  border: 0.0625rem solid rgba(255, 217, 86, 0.5);
}
.form-control:focus {
  border: 0.0625rem solid var(--bs-primary);
}
.react-select__control {
  border: 0.0625rem solid transparent !important;
}
.react-select__control:hover {
  border: 0.0625rem solid rgba(255, 217, 86, 0.5) !important;
}
.react-select__control:focus {
  border: 0.0625rem solid var(--bs-primary);
}
.form-select {
  outline: none !important;
  box-shadow: none !important;
  font-family: var(--bs-body-font-family);
  border-radius: var(--border-radius);
  resize: none;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
}
.form-select:focus,
.form-select {
  border: 0.0625rem solid var(--bs-primary) !important;
}
.form-select:focus {
  color: var(--bs-secondary);
}
.form-label {
  font-size: 1rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.switch-input {
  position: relative;
  width: 2.625rem;
  height: 1.375rem;
}
.switch-input input[type='checkbox'] {
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  height: 1.375rem;
  background: rgba(255, 255, 255, 0.3);
  outline: none;
  border-radius: 0.75rem;
  box-shadow: inset 0rem 0rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}
.switch-input input[type='checkbox']:checked {
  background: var(--bs-primary);
}
.switch-input input[type='checkbox']:before {
  content: '';
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.0625rem;
  background: #fff;
  border-radius: 50%;
  top: 0.0625rem;
  transform: scale(1.1);
  transition: 0.5s;
  box-shadow: 0 0.125rem 0.1875rem rgb(0, 0, 0, 0.4);
}
.switch-input input[type='checkbox']:checked:before {
  left: 1.25rem;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.mb-15 {
  margin-bottom: 0.9375rem !important;
}
.mb-20 {
  margin-bottom: 1.25rem !important;
}
.pr-15 {
  padding-right: 0.9375rem;
}
.mb-5 {
  margin-bottom: 0.3125rem !important;
}
.mb-10 {
  margin-bottom: 0.625rem !important;
}
.pt-5 {
  padding-top: 0.3125rem !important;
}
.pb-5 {
  padding-bottom: 0.3125rem !important;
}
.pt-10 {
  padding-top: 0.625rem !important;
}
.pb-10 {
  padding-bottom: 0.625rem !important;
}
.pt-15 {
  padding-top: 0.9375rem;
}
.pb-15 {
  padding-bottom: 0.9375rem;
}
.pt-20 {
  padding-top: 1.25rem;
}
.pb-20 {
  padding-bottom: 1.25rem;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  margin-left: 0.3125rem;
}
a,
a:hover {
  color: var(--bs-primary);
  text-decoration: underline;
}
.form-check-input:checked[type='radio'] {
  background-image: none !important;
  border-color: var(--bs-white);
}

.googleBtnColor {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 251, 217, 1) 100% rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 251, 217, 1) 100% rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(90deg, rgba(255, 251, 217, 1) 100% rgba(255, 251, 217, 1) 100%);
}

.my-font,
.my-font:hover,
.my-font:active,
.my-font:focus {
  font-family: var(--bs-font-menu) !important;
  font-size: 16px;
  color: white;
}
