.text-brand-gradient {
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input[type='range'] {
  height: 27px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: transparent;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%) !important;
  border-radius: 50px;
  border: 0px solid #000000;
}

input[type='range']::-webkit-slider-thumb {
  box-shadow: 1px 1px 6px #ffe98f;
  border: 0px solid #ffd956;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #ffd956;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #c7c7c7;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #c7c7c7;
  border-radius: 50px;
  border: 0px solid #000000;
}

input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 6px #ffe98f;
  border: 0px solid #ffd956;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #ffd956;
  cursor: pointer;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #c7c7c7;
  border: 0px solid #000000;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}

input[type='range']::-ms-fill-upper {
  background: #c7c7c7;
  border: 0px solid #000000;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}

input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 6px #ffe98f;
  border: 0px solid #ffd956;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #ffd956;
  cursor: pointer;
}

input[type='range']:focus::-ms-fill-lower {
  background: #c7c7c7;
}

input[type='range']:focus::-ms-fill-upper {
  background: #c7c7c7;
}

/* utilities */
.bg-brand {
  background-color: #222146;
}

.border-gold {
  border: solid 0.0625rem rgba(var(--bs-primary-rgb), 0.5);
}
