.card:hover {
  border: 1px solid rgba(121, 122, 131, 0.35);
}

.my-accordion-item:hover {
  border: 1px solid rgba(121, 122, 131, 1);
}

.my-accordion-item {
  border: 1px solid #171630;
}

.booster-ad-wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
  padding: 1.25rem 0;
  padding-left: 18.125rem;
}

.booster-ad-card {
  padding: 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--border-radius);
  text-decoration: none;
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  height: calc(100% - 40px);
  min-height: 34.375rem;
}

.booster-ad-card .card .p-amt {
  color: #fff;
  /* margin-top: 0.9375rem; */
  margin-bottom: 0;
  padding: 0.75rem;
  font-size: 0.75rem;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
}

.booster-ad-card .card .p-amt h3 {
  font-size: 1rem;
  color: var(--bs-white);
}

.booster-ad-card .card .p-amt p {
  font-size: 0.875rem;
  color: var(--bs-primary);
}

.btn-withdraw-b {
  margin: auto;
  display: block;
  margin-top: 0.9375rem !important;
}

.ord-list-ba {
  font-size: 0.8125rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.ord-list-ba + .ord-list-ba {
  border-top: 1px solid rgba(121, 122, 131, 0.35);
}

.ord-list-ba .th-img {
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 0.625rem;
}

.ord-list-ba h4 {
  font-size: 0.875rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
}

.ord-list-ba h4 span {
  font-size: 1rem;
  font-weight: normal;
  font-family: var(--bs-body-font-family);
  display: block;
  -webkit-text-fill-color: #fff;
  padding-top: 0.3125rem;
}

.ord-list-ba h4 span svg {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
}

.ord-list-ba .ord-amt {
  font-size: 1.25rem;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: normal;
}

.Claim-new-order {
  text-align: center;
  padding-top: 1.5625rem;
}

.set-h {
  min-height: auto;
}

.Claim-new-order .btn-primary {
  background: #8a8a8c;
}

.list-tra .title {
  color: var(--bs-primary);
  font-family: var(--bs-font-sans-serif);
  padding-top: 0.75rem;
}

.ord-list-ba a,
.list-tra a {
  font-family: var(--bs-font-sans-serif);
}

.list-tra svg {
  width: 1rem;
  height: 1rem;
  margin-top: -0.1875rem;
  margin-left: 0.3125rem;
}

.list-tra-row {
  font-size: 0.75rem;
}

.list-tra-row + .list-tra-row {
  border-top: 1px solid rgba(121, 122, 131, 0.35);
}

.list-tra-row {
  font-size: 0.8125rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.all-t-earning,
.v-earning {
  font-size: 0.75rem;
  text-align: right;
  line-height: 1;
  color: #c1c1c1;
}

.al-e-val,
.v-earning span {
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--bs-font-sans-serif);
  display: block;
  font-size: 1.875rem;
  line-height: 1;
  padding-top: 0.3125rem;
}

.Claim-new-order .btn-primary:hover,
.Claim-new-order .btn-primary:focus {
  background: var(--bs-primary);
}

.booster-ad-card .card .p-amt h2.amt {
  font-size: 1rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.5625rem;
}

.NavBar {
  position: fixed;
  left: 10px;
  top: 20px;
  transition: all 0.2s ease;
  z-index: 100;
  width: 280px;
  border-radius: var(--border-radius);
}

.ad-logo {
  margin: auto;
  display: block;
  text-align: center;
  padding-top: 0.3125rem;
}

.ad-logo img {
  width: auto;
  height: 4.375rem;
  margin: auto;
}

.leftnav ul {
  padding: 0;
  margin: 0;
  margin: 0rem 0;
  list-style: none;
  margin-top: 1.875rem;
}

.leftnav ul li {
  margin-top: 0rem;
  display: block;
}

.leftnav ul li + li {
  margin-top: 0.9375rem;
}

.leftnav ul li a {
  color: var(--bs-white);
  font-family: var(--bs-font-menu);
  font-size: 0.9375rem;
  padding: 1rem 1rem;
  display: flex;
  line-height: 1.125rem;
  border-radius: var(--border-radius);
  text-decoration: none;
  background: transparent;
}

.leftnav ul li a:hover,
.leftnav ul li a.active {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFD956", endColorstr="#FFFBD9", GradientType=1);
  /* background: var(--bs-primary); */
  color: var(--bs-secondary);
}

.leftnav svg {
  width: 1.125rem;
  height: 1.125rem;
  fill: var(--bs-white);
  margin-right: 0.9375rem;
  margin-top: -0.125rem;
}

.leftnav li > svg {
  min-width: 2rem;
  margin-left: -1.25rem;
  margin-right: 0;
}

.leftnav a.active svg {
  fill: var(--bs-dark);
}

.leftnav ul li a:hover svg,
.btn-outline:hover svg,
.btn-outline:focus svg {
  fill: var(--bs-secondary);
}

.booster-ad-card h1 {
  font-size: 1.75rem;
  margin-bottom: 0.3125rem;
}

.booster-ad-card h2 {
  font-size: 1.625rem;
  margin-bottom: 0.625rem;
}

.booster-ad-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.3125rem;
}

.booster-ad-card p {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.tophead {
  border-bottom: 1px solid rgba(121, 122, 131, 0.35);
  padding-bottom: 0.625rem;
  margin-bottom: 0.9375rem;
}

.tophead .th-actions {
  text-align: right;
}

.tophead .th-actions {
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;
}

.tophead .th-actions .btn-primary {
  padding: 0.625rem 0.75rem;
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    90deg,
    rgba(244, 222, 147, 0.2) 0%,
    rgba(26, 156, 251, 0.2) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(244, 222, 147, 0.2) 0%,
    rgba(26, 156, 251, 0.2) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(244, 222, 147, 0.2) 0%,
    rgba(26, 156, 251, 0.2) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.2)", endColorstr="#rgba(26,156,251,0.2)", GradientType=1);
  margin-left: 0.625rem;
}

.tophead .th-actions .btn-primary:hover,
.tophead .th-actions .btn-primary:focus {
  background: var(--bs-primary);
  color: var(--bs-secondary);
  text-decoration: none;
}

.tophead .th-actions .btn-primary svg {
  fill: var(--bs-white);
  width: 1rem;
  height: 1rem;
}

.tophead .th-actions .btn-primary:hover svg,
.tophead .th-actions .btn-primary:focus svg {
  fill: var(--bs-secondary);
}

.tophead .th-actions .btn-add span {
  padding-left: 0.625rem;
  margin: auto 0;
}

.tophead .th-actions .btn-add {
  padding: 0.625rem 1.125rem;
  border: solid 0.0625rem #fff;
  display: inline-flex;
}

.menu-icon,
.menu-icon:focus,
.menu-icon:hover {
  padding: 0;
  background-color: transparent !important;
  border: none;
  color: var(--bs-primary);
}

.menu-icon svg {
  width: 1.625rem;
  height: 1.625rem;
  fill: var(--bs-primary);
  margin-right: 0.9375rem;
}

.mob-logo {
  height: 2.125rem;
  width: auto;
}

.mob-nav {
  float: left;
  display: none;
}

.NavBar .btn-close {
  display: none;
}

.booster-ad-card .card {
  background-color: var(--bs-secondary);
  color: var(--bs-white);
  border-radius: var(--border-radius);
  padding: 0.9375rem;
  margin-bottom: 1.875rem;
}

.booster-ad-card .card p {
  font-size: 0.75rem;
  color: #c1c1c1;
}

.silver-icon,
.gold-icon {
  text-align: center;
  font-size: 1rem;
}

.gold-icon img,
.silver-icon img {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.3125rem;
  border-radius: var(--border-radius);
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  width: max-content;
  width: 4.0625rem;
  height: 4.0625rem;
  margin: auto;
  display: block;
  margin-bottom: 0.625rem;
}

.gold-icon img {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  border: solid 0.0625rem var(--bs-primary);
}

.arr-right {
  margin: auto;
  width: 60px;
  height: 60px;
  /* margin-top: 1.0625rem; */
}

.gold-icon {
  color: var(--bs-primary);
}

.btn-ord svg {
  margin-left: 0.3125rem;
  height: 1rem;
  width: 1rem;
  margin-top: -0.125rem;
}

.btn-ord,
.btn-ord:focus,
.btn-ord:hover {
  padding: 0.625rem 0.75rem;
  border-radius: 0.375rem;
}

.ord-list {
  font-size: 0.8125rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.ord-list + .ord-list {
  border-top: solid 0.0625rem rgba(121, 122, 131, 0.35);
}

.ord-list .th-img {
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 0.625rem;
}

.ord-list h4 {
  font-size: 1rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
}

.ord-list h4 span {
  font-size: 0.8125rem;
  font-weight: normal;
  font-family: var(--bs-body-font-family);
  display: block;
  -webkit-text-fill-color: #c1c1c1;
  padding-top: 0.3125rem;
}

.btn-outline {
  border: solid 0.0625rem #c1c1c1 !important;
  color: var(--bs-white);
  background: transparent;
  width: fit-content;
  padding: 0.625rem 0.9375rem;
  margin-top: auto;
}

.btn-outline svg {
  margin-right: 0.5rem;
  fill: white;
  width: 1.125rem;
  height: 1.125rem;
}

.dash-right {
  min-height: 9rem;
  display: grid;
}

.dash-right h2 {
  font-size: 1.5rem;
}

.dash-right h2 span {
  color: var(--bs-primary);
}

.join-bg-img,
.code-bg-img,
.support-bg-img,
.rate-bg-img {
  background-image: url(../images/dash-bg-001.webp);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.code-bg-img {
  background-image: url(../images/dash-bg-002.webp);
}

.support-bg-img {
  background-image: url(../images/dash-bg-003.webp);
}

.rate-bg-img {
  background-image: url(../images/dash-bg-lux.webp);
}

.orderList {
  min-height: 31.4375rem;
}

.empty-ord {
  text-align: center;
  margin: auto;
  width: 100%;
}

.empty-ord h3 {
  color: var(--bs-primary);
}

.empty-ord p {
  color: var(--bs-white);
}

.empty-ord img {
  margin: auto;
  display: block;
}

.order-detail .th-ord-img {
  width: 6rem;
  height: 6rem;
  border-radius: var(--border-radius);
  object-fit: cover;
}

.order-detail .icon-g {
  font-size: 1.125rem;
  display: block;
}

.order-detail .icon-g img {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.5rem;
}

.order-detail .role {
  color: var(--bs-primary);
}

.ord-rating {
  font-size: 0.875rem;
}

.ord-rating a {
  margin-left: 0.625rem;
}

.ord-rating .r-start {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3125rem;
  float: left;
}

.lang-info {
  padding-top: 0.9375rem;
  display: inline-flex;
}

.lang-info span img {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
}

.lang-info span {
  color: #fff;
  font-size: 0.8125rem;
  margin-right: 0.3125rem;
  padding: 0.4375rem 1rem;
  border-radius: 1.5625rem;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
}

.hours {
  border-radius: var(--border-radius);
  border: solid 0.0625rem #fff;
  font-size: 0.9375rem;
  text-align: center;
  padding: 0.5rem;
  margin-top: 0.625rem;
  line-height: 1;
}

.booster-ad-card .card .Ranked {
  color: #fff;
  margin-bottom: 0.9375rem;
  margin-top: 0.3125rem;
  padding: 0.4375rem;
  font-size: 0.75rem;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
}

.booster-ad-card .card .Ranked .rank-img {
  width: 2.5rem;
  height: 2.5rem;
}

.booster-ad-card .card .Ranked h3 {
  font-size: 0.9375rem;
  color: var(--bs-primary);
  padding-top: 0.3125rem;
  margin-bottom: 0;
}

.booster-ad-card .card .Ranked h3 span {
  color: var(--bs-white);
  display: block;
  padding-top: 0.1875rem;
}

.form-info {
  padding-top: 0.3125rem;
}

.form-info .btn-primary {
  padding: 0.5rem 1.125rem;
  margin-top: 0.3125rem;
}

.form-info .form-label {
  font-size: 0.8125rem;
}

.form-input {
  background: 0 0;
  position: relative;
  width: 100%;
  display: block;
  cursor: text;
  transition: border-color 0.3s, margin-bottom 0.3s;
}

.form-input--file {
  display: block;
  text-align: center;
  border: 0.0625rem dashed #d5d5d5;
  color: rgb(190, 190, 190);
  line-height: 2rem;
  font-size: 0.75rem;
  padding: 0 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.375rem;
}

.form-input--file .placeholder {
  position: relative;
  font-size: 0.75rem;
  line-height: 1;
}

.form-input--file input {
  display: none;
}

.form-input--file:hover {
  border: 0.0625rem dashed var(--bs-primary);
}

.form-input--file:hover .placeholder:before {
  color: var(--bs-primary);
}

.booster-ad-card .card .extraOp {
  min-height: 5.3125rem;
}

.booster-ad-card .card .extraOp h3 {
  margin-bottom: 0.9375rem;
  color: #fff;
  font-size: 0.8125rem;
}

/* .q-start {
  margin-top: 0.3125rem;
} */

.booster-ad-card .card .q-start p {
  font-size: 0.6875rem;
}

.q-start .icon-rd-value {
  font-family: var(--bs-font-sans-serif);
  font-size: 3.375rem;
  line-height: 2.8125rem;
  display: block;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-rd-value {
  margin-right: 0.625rem;
}

.range-label {
  font-size: 0.8125rem;
  display: block;
  margin-bottom: 0.3125rem;
}

.form-range {
  width: 100%;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.0625rem var(--bs-primary),
    0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.3);
  background-color: var(--bs-primary);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 0.0625rem var(--bs-primary),
    0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.2);
  background-color: var(--bs-primary);
}

.form-range::-moz-focus-outer {
  border: 0.1875rem;
}

.form-range::-webkit-slider-thumb {
  width: 1.3rem;
  height: 1.3rem;
  margin-top: -0.35rem;
  background-color: var(--bs-primary);
  border-radius: 1.3rem;
}

.q-start .btn-primary {
  display: block;
  margin-left: auto;
  margin-bottom: 0.9375rem;
}

.booster-ad-card .card .match-history {
  padding: 0.89rem 0.4375rem;
}

.booster-ad-card .card .match-history .mh-l-img {
  width: 3.5rem;
  height: 3.5rem;
  float: left;
  border-radius: 0.3125rem;
}

.booster-ad-card .card .match-history .mh-s-img {
  width: 1.5rem;
  height: 1.5rem;
  float: left;
  margin: 0rem 0rem 0.3125rem 0.3125rem;
  border-radius: 0.3125rem;
}

.mh-s {
  min-width: 6.9375rem;
  padding-right: 0;
  padding-left: 0.625rem;
  margin-top: auto;
  margin-bottom: auto;
}

.booster-ad-card .card .match-history .t-ranked {
  font-size: 0.75rem;
  color: #c1c1c1;
  font-family: var(--bs-font-sans-serif);
  line-height: 0.8125rem;
}

.booster-ad-card .card .match-history .t-ranked span {
  font-size: 0.625rem;
  display: block;
}

.booster-ad-card .card .match-history .lp-val {
  font-size: 0.75rem;
  font-family: var(--bs-font-sans-serif);
  line-height: 0.9375rem;
  color: var(--bs-primary);
}

.booster-ad-card .card .match-history .lp-val span {
  color: #c1c1c1;
  font-family: var(--bs-font-menu);
}

.booster-ad-card .card .match-history .lp-val svg {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-top: -0.125rem;
  margin-right: 0.1875rem;
}

.chat-box {
  /* height: 43.375rem; */
  padding: 0.125rem;
  margin-bottom: 0.9375rem;
}

.chat-box .chat-head {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  border: solid 0.0625rem var(--bs-primary);
  padding: 0.9375rem 0.625rem 0.625rem 0.625rem;
  color: var(--bs-secondary);
  text-align: center;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.chat-box .chat-head h3 {
  font-size: 1.125rem;
}

.chat-box .chat-head p {
  font-size: 0.6875rem;
  margin: auto;
}

.chat-box .chat-midd {
  /* display: grid; */
  height: 32.8rem;
  background-color: rgb(23, 22, 48);
  background-image: url(../images/chat-bg.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  max-height: 30rem;
}

.chat-box .chat-midd .lastview {
  text-align: center;
  display: block;
  color: var(--bs-primary);
  font-size: 0.8125rem;
  padding: 0.625rem;
}

.chat-box .chat-msg {
  margin-top: auto;
  margin-bottom: 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.chat-box .chat-msg ul {
  /* padding-left: 0rem;
  margin-left: 0rem; margin-bottom: 0;
  border: none; list-style: none; display: flex; height: 100%;
  flex-wrap: wrap; height: auto; */
  padding-left: 0rem;
  margin-left: 0rem;
  margin-bottom: 0;
  border: none;
  list-style: none;
  margin-top: auto;
}

.chat-box .chat-msg ul li {
  position: relative;
  margin-top: auto;
  margin-bottom: 0.25rem;
  padding-left: 3.125rem;
  color: #fff;
}

.chat-box .chat-msg ul li .avatar {
  position: absolute;
  top: 0;
  left: 0.625rem;
  border-radius: 50%;
  padding-bottom: 0.4rem;
  text-align: center;
  width: 2.1875rem;
  height: 2.1875rem;
  overflow: hidden;
  /* border: 0.1rem solid rgba(172, 171, 171, 0.9); */
}

.chat-box .chat-msg ul li .avatar img {
  width: 2.1875rem;
  height: 2.1875rem;
}

.chat-box .chat-msg ul li .msg-info {
  padding-left: 0.3125rem;
}

.chat-box .chat-msg ul li .msg-info .msgdiv {
  margin-bottom: 0;
  position: relative;
  background: rgb(55, 51, 63);
  background: -moz-linear-gradient(
    130deg,
    rgba(55, 51, 63, 0.9) 0%,
    rgba(27, 38, 68, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(55, 51, 63, 0.9) 0%,
    rgba(27, 38, 68, 0.8) 100%
  );
  background: linear-gradient(
    130deg,
    rgba(55, 51, 63, 0.9) 0%,
    rgba(27, 38, 68, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(55, 51, 63, 0.9)", endColorstr="#rgba(27, 38, 68, 0.8)", GradientType=1);
  padding: 0.5rem;
  padding-bottom: 0;
  margin-right: 8px;
  border-radius: var(--border-radius);
  border: 0.0625rem solid rgba(172, 171, 171, 0.1);
}

.chat-box .chat-msg ul li .msg-info .msgdiv p {
  font-size: 0.75rem;
}

.chat-box .chat-msg ul li .msg-info small {
  font-size: 0.6875rem;
  opacity: 0.5;
}

.chat-box .chat-msg ul li.right {
  padding-right: 3.125rem;
  padding-left: 0;
}

.chat-box .chat-msg ul li.right .msg-info .msgdiv {
  margin-left: 0.5rem;
  background: rgba(23, 22, 48, 0.8);
}

.chat-box .chat-msg ul li.right .msg-info small {
  margin-left: 0.9375rem;
}

.chat-box .chat-msg ul li.right .avatar {
  left: auto;
  right: 0.625rem;
}

.chat-box .chat-bottom {
  margin-top: auto;
  position: relative;
  background-color: var(--bs-secondary);
  padding: 0.9375rem;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.chat-box .chat-bottom button {
  background-color: transparent;
  border: none;
  padding: 0.3125rem;
  position: absolute;
  right: 1.25rem;
  top: 1.0625rem;
}

.chat-box .chat-bottom button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.chat-box .chat-bottom input {
  font-size: 0.8125rem;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
}

.chat-box .chat-bottom .form-check {
  margin-top: 0.625rem;
}

.chat-box .chat-bottom .form-check-label {
  font-size: 0.6875rem;
  margin-left: 0.3125rem;
}

.chat-box .chat-bottom .form-check-input:checked[type='checkbox'] {
  background-size: 100%;
}

.order-sec-m .card {
  margin-bottom: 0.9375rem;
}

.form-check-input:checked {
  background-color: var(--bs-primary) !important;
}

.account-list {
  padding: 0.625rem !important;
}

.account-list h3 {
  margin-bottom: 0.3125rem;
}

.account-list .as-t {
  font-size: 0.875rem;
  display: block;
  line-height: 1;
}

.account-list h3 {
  font-size: 1rem;
}

.account-list .acc-am {
  color: var(--bs-primary);
  font-size: 0.8125rem;
}

.account-list .p-date {
  font-size: 0.8125rem;
  margin-top: auto;
  margin-bottom: auto;
}

.account-list .p-date b {
  font-size: 0.9375rem;
  font-weight: bold;
  display: block;
  color: var(--bs-primary);
}

.account-list .silver-icon img {
  margin-bottom: 0;
  height: 3.4375rem;
  width: 3.4375rem;
}

.timeline {
  border-left: solid 0.125rem var(--bs-primary);
  margin-left: 0.625rem;
  padding: 0;
  list-style: none;
  /* margin-top: 2.5rem; */
}

.timeline li {
  margin-bottom: 2.375rem;
  font-size: 0.875rem;
  padding-left: 1.25rem;
  position: relative;
}

.timeline li::before {
  content: '';
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 0;
  left: -0.5625rem;
  border-radius: 50%;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  border: solid 0.0625rem var(--bs-primary);
  z-index: 2;
}

.timeline li:last-child::after {
  background: var(--bs-secondary);
  z-index: 1;
  content: '';
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: -0.625rem;
  top: 0.625rem;
}

.timeline li span {
  color: #ffea96;
}

.ord-status {
  width: 7rem;
  text-align: center;
  font-size: 0.8125rem;
  padding: 0.4375rem 0.5rem;
  border: solid 0.0625rem var(--bs-primary);
  border-radius: 0.375rem;
  line-height: 1;
  background: rgba(255, 245, 0, 0.1);
}

.st-complete {
  border: solid 0.0625rem #52b4fd;
  background: rgba(26, 156, 251, 0.1);
}

.st-in-progress {
  border: solid 0.0625rem #2ed49d;
  background: rgba(0, 182, 122, 0.1);
}

.min-ha {
  min-height: auto;
}

.empty-account {
  padding-bottom: 0.9375rem;
}

.empty-account h1.title {
  color: var(--bs-primary);
  font-size: 2rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.625rem;
}

.empty-account p {
  margin-bottom: 0.9375rem;
}

.empty-account .acc-empty-img {
  width: auto;
  height: 25rem;
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.support-sec {
  background-image: url(../images/support-bg.webp);
  background-position: bottom right;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: calc(100vh - 5rem);
}

.support-sec .empty-account .btn-primary svg {
  fill: var(--bs-secondary);
  margin-right: 0.3125rem;
}

.support-sec .empty-account {
  margin-bottom: 2.8125rem;
}

.setting-tabs .nav-pills .nav-link.active,
.setting-tabs .nav-pills .show > .nav-link,
.setting-tabs .nav-pills .nav-link {
  color: #fff;
  background-color: transparent;
  border: none;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  letter-spacing: 0.0313rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.setting-tabs .nav-pills .nav-link {
  color: #adadad;
}

.set-profile-img {
  padding-right: 1.875rem;
}

.set-profile-img h5 {
  font-size: 1rem;
}

.set-profile-img img {
  display: block;
  margin: 0.9375rem 0;
  height: 9.375rem;
  width: auto;
}

.set-profile-img span {
  display: block;
  text-align: center;
  font-size: 0.8125rem;
}

.set-profile-img span a {
  color: #fff;
}

.set-save {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-left: auto;
  display: block;
  margin-top: 1.625rem;
}

.form-label {
  font-size: 0.875rem;
}

.setting-tabs .input-group-text {
  border: solid 0.125rem #2f2e45;
  background-color: transparent;
}

.setting-tabs .input-group-text {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.setting-tabs .input-group-text svg {
  fill: #fff;
}

@media (max-width: 85.375rem) {
  .leftnav ul li a {
    padding: 0.75rem 0.625rem;
    line-height: 0.9375rem;
  }

  .leftnav ul li a svg {
    width: 1rem;
    height: 1rem;
    margin-top: -0.0625rem;
    margin-right: 0.625rem;
  }

  .order-sec-m .gold-icon img,
  .order-sec-m .silver-icon img {
    width: 3.625rem;
    height: 3.625rem;
  }

  .order-sec-m .silver-icon,
  .order-sec-m .gold-icon {
    font-size: 0.8125rem;
  }

  .order-sec-m .card .Ranked h3 {
    padding-top: 0.375rem !important;
    margin-bottom: 0.1875rem;
  }

  .NavBar {
    width: 15.625rem;
  }

  .booster-ad-wrapper {
    padding-left: 16.1875rem;
  }

  .empty-account .acc-empty-img {
    height: 23.125rem;
  }
}

@media (max-width: 64rem) {
  .booster-ad-card {
    padding: 0.9375rem;
  }

  .booster-ad-card h1 {
    font-size: 1.5625rem;
  }

  .order-sec-m h3 {
    font-size: 1.125rem;
  }

  .chat-box .chat-midd {
    height: 29.6875rem;
  }

  .NavBar {
    width: 13.125rem;
    padding: 0.625rem;
  }

  .booster-ad-wrapper {
    padding-left: 13.0625rem;
  }

  .leftnav ul li a {
    font-size: 0.8125rem;
  }

  .ad-logo img {
    height: 3.75rem;
  }

  .empty-account h1.title {
    font-size: 1.875rem;
  }

  .empty-account .acc-empty-img {
    height: 20.625rem;
  }

  .set-profile-img img {
    height: 8.125rem;
  }

  .set-profile-img {
    padding-right: 0.9375rem;
  }
}

@media (max-width: 1000px) {
  .order-sec-m > div {
    margin: auto;
  }
}

@media (max-width: 50rem) {
  .booster-ad-wrapper {
    padding-left: 0;
  }

  .container {
    max-width: 100%;
  }

  .chat-box {
    height: 30.625rem;
  }

  .chat-box .chat-midd {
    height: 19.75rem;
    background-image: url(../images/chat-bg-sm.jpg);
    background-position: center center;
    background-size: 100%;
  }

  .order-sec-m .gold-icon img,
  .order-sec-m .silver-icon img {
    width: 4.0625rem;
    height: 4.0625rem;
  }

  .booster-ad-card p {
    font-size: 0.8125rem;
  }

  .order-sec-m h3 {
    font-size: 1.25rem;
  }

  .order-sec-m .silver-icon,
  .order-sec-m .gold-icon {
    font-size: 1rem;
  }

  .booster-ad-card .card .Ranked {
    margin-top: 0.0625rem;
  }

  .booster-ad-card h1 {
    font-size: 1.375rem;
  }

  .NavBar {
    position: fixed;
    left: -18.125rem;
    z-index: 100;
    top: 0;
    height: 100vh;
    background: #2d2e41;
    background: -moz-linear-gradient(90deg, #2d2e41 0%, #2c3048 100%);
    background: -webkit-linear-gradient(90deg, #2d2e41 0%, #2c3048 100%);
    background: linear-gradient(90deg, #2d2e41 0%, #2c3048 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2d2e41", endColorstr="#2c3048", GradientType=1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 18.125rem;
    transition: all 0.2s ease;
  }

  .ad-logo img {
    height: 3.125rem;
  }

  .NavBar .btn-close {
    opacity: 0.75;
    filter: invert(1);
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    display: block;
  }

  .NavBar-side .NavBar {
    left: 0;
    width: 15rem;
  }

  .NavBar-side .tab-screen {
    opacity: 0.5;
    filter: grayscale(1);
    filter: blur(0.125rem);
    pointer-events: none;
  }

  .leftnav ul li a {
    font-size: 0.9375rem;
  }

  .tophead .th-actions .btn-add {
    padding: 0.625rem 0.75rem;
  }

  .tophead .th-actions .btn-add span {
    display: none;
  }

  .row-reverse {
    flex-direction: column-reverse;
  }

  .tophead .th-actions .btn-primary {
    padding: 0.625rem 0.625rem;
    text-decoration: none;
  }

  .sm-m {
    margin-top: 1.25rem;
  }

  .mob-nav {
    float: left;
    display: block;
  }

  .empty-account h1.title {
    font-size: 1.625rem;
  }

  .empty-account .acc-empty-img {
    height: 18.75rem;
  }
}

@media (max-width: 47.9375rem) {
  .empty-ord img {
    width: 11.875rem;
  }

  .chat-box {
    height: 25.625rem;
  }

  .chat-box .chat-midd {
    height: 14.75rem;
  }

  .account-list h3 {
    font-size: 1rem;
  }

  .account-list .p-date b {
    font-size: 0.8125rem;
  }

  .empty-account h1.title {
    font-size: 1.5rem;
  }

  .empty-account .acc-empty-img {
    height: 16.875rem;
  }

  .setting-tabs .nav-pills .nav-link.active,
  .setting-tabs .nav-pills .show > .nav-link,
  .setting-tabs .nav-pills .nav-link {
    font-size: 0.875rem;
    padding-top: 0.1875rem;
    padding-bottom: 0.1875rem;
  }

  .set-profile-img img {
    height: 6.25rem;
  }
}

@media (max-width: 30rem) {
  .btn-primary,
  .btn {
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
    line-height: 1;
  }

  .set-save {
    font-size: 0.875rem;
    margin-top: 0;
    padding: 0.75rem 2rem;
  }

  .viewlog {
    width: 100%;
    padding-top: 0.625rem;
  }

  .viewlog .btn-primary {
    padding: 0.5rem 1.125rem;
  }

  .account-list .p-date {
    margin: 0;
  }

  .ord-status {
    font-size: 0.6875rem;
    padding: 0.3125rem 0.375rem;
  }

  .empty-account .acc-empty-img {
    height: 15rem;
  }

  .support-sec {
    background-size: auto 65%;
  }

  .support-sec .empty-account {
    margin-bottom: 1.5625rem;
  }

  .support-sec .empty-account .btn-primary svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  .set-profile-img {
    width: 100%;
  }

  .set-profile-img img {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 26.5625rem) {
  .mh-s {
    flex: auto;
    padding-bottom: 0.625rem;
    padding-top: 0.375rem;
    width: 100%;
  }

  .booster-ad-card .card .match-history .mh-l-img {
    width: 4.25rem;
    height: 4.25rem;
  }

  .booster-ad-card .card .match-history .t-ranked {
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }

  .booster-ad-card .card .match-history .t-ranked span {
    font-size: 0.875rem;
  }

  .booster-ad-card .card .match-history .lp-val {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    padding-top: 0.1875rem;
  }

  .booster-ad-card .card .match-history .mh-s-img {
    width: 2.25rem;
    height: 2.25rem;
    margin: 0rem 0rem 0.5rem 0.625rem;
  }

  .ord-rating {
    margin-top: 0.3125rem;
  }

  .lang-info span {
    font-size: 0.75rem;
    margin-right: 0.3125rem;
    padding: 0.375rem 0.625rem;
    border-radius: 1.125rem;
  }

  .leftnav ul {
    margin-top: 1rem;
  }

  .leftnav ul li + li {
    margin-top: 0.625rem;
  }

  .leftnav ul li a {
    padding: 0.625rem 0.625rem;
  }

  .dash-right {
    padding: 0.3125rem;
  }

  .chat-box .chat-head h3 {
    font-size: 1rem;
  }

  .chat-box .chat-midd .lastview {
    padding: 0.3125rem;
    font-size: 0.75rem;
  }

  .chat-box .chat-midd {
    grid-template-rows: 1.6875rem auto;
  }

  .chat-box .chat-bottom {
    padding: 0.625rem;
  }

  .chat-box .chat-bottom button {
    right: 0.875rem;
    top: 0.8125rem;
  }

  .chat-box .chat-head {
    padding: 0.5rem 0.375rem 0.375rem 0.375rem;
  }

  .chat-box {
    height: 24.25rem;
  }

  .ord-list-d2 .btn-ord {
    margin-top: 0.625rem;
  }

  .ord-status {
    border-radius: 0.25rem;
    margin-right: 0.8125rem;
  }

  .empty-account h1.title {
    font-size: 1.375rem;
  }

  .empty-account .acc-empty-img {
    height: 12.5rem;
  }

  .setting-tabs .nav-pills .nav-link.active,
  .setting-tabs .nav-pills .show > .nav-link,
  .setting-tabs .nav-pills .nav-link {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

@media (max-width: 23.4375rem) {
  .booster-ad-card h1 {
    font-size: 1.25rem;
  }

  .booster-ad-card p {
    font-size: 0.75rem;
  }

  .booster-ad-card {
    padding: 0.625rem;
  }

  .booster-ad-card .card {
    padding: 0.625rem;
  }

  .btn-ord {
    font-size: 0.8125rem;
    padding: 0.4375rem 0.5625rem;
  }

  .btn-ord svg {
    height: 0.875rem;
    width: 0.875rem;
  }

  .booster-ad-card h3 {
    font-size: 1.125rem;
  }

  .ord-list h4 {
    font-size: 0.9375rem;
  }

  .dash-right .btn-outline {
    font-size: 0.8125rem;
  }

  .account-list h3 {
    font-size: 0.9375rem;
  }
}

@media (max-width: 20rem) {
  .chat-box .chat-head p {
    font-size: 0.6563rem;
  }

  .chat-box {
    height: 24.625rem;
  }

  .booster-ad-card h1 {
    font-size: 1.125rem;
  }

  .order-detail .icon-g {
    font-size: 1rem;
  }

  .lang-info span img {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.25rem;
  }

  .lang-info span {
    font-size: 0.6875rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.625rem;
  }

  .booster-ad-card .card .match-history .mh-s-img {
    width: 1.875rem;
    height: 1.875rem;
    margin: 0rem 0rem 0.3125rem 0.625rem;
  }

  .account-list .p-date {
    width: 100%;
    padding-top: 0.625rem;
  }
}

.rules {
  padding-top: 0.625rem;
}

.rules h3 {
  font-size: 1.25rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rules p {
  margin-left: 0.9375rem;
  margin-bottom: 1.875rem;
}

.set-h {
  min-height: auto;
}

/* .leftnav ul li ul li a:hover, */
/* .leftnav ul li ul li a.nav-link.active .leftnav ul li ul li a {
  color: #93959f;
  font-family: var(--bs-font-regular);
} */

@keyframes fade-in-slide-up {
  to {
    opacity: 1;
  }
}
