*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background-color: rgba(193, 193, 193, 0.2); /* #c1c1c1 with 20% opacity */
  border-radius: 16px;
}
*::-webkit-scrollbar-track:hover {
  background-color: rgba(193, 193, 193, 0.3); /* #c1c1c1 with 30% opacity */
  border-radius: 16px;
}
*::-webkit-scrollbar-thumb {
  background-color: #ffd957;
  border-radius: 16px;
}
*::-webkit-scrollbar-thumb:active {
  background-color: #ffbb00;
  border-radius: 16px;
}

.btn-primary:focus-visible {
  border: 2px solid #171630;
}

.header {
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.h-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  min-width: 80rem;
}

.header .h-content h1 {
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 1.5625rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header .h-content h3 {
  font-size: 1.5625rem;
  line-height: 1;
  font-family: var(--bs-body-font-family);
  font-weight: normal;
  margin-bottom: 2.1875rem;
}

.header figure {
  margin: 0;
}

.header img {
  width: 100%;
  height: auto;
}

.header .btn-primary,
.header .btn-outline-light,
.Our-Safety .btn-primary {
  font-size: 1rem;
  padding: 1rem 2rem;
  margin: 0 0.625rem;
}

.header .btn-primary {
  color: var(--bs-secondary);
}

.our-services {
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 3.75rem 0 16.125rem 0;
  color: var(--bs-secondary);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(180deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(180deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  overflow: hidden;
}

.container-1170 {
  max-width: 73.125rem !important;
}

.our-services h2,
.premium-features h2 {
  font-size: 3.75rem;
  margin-bottom: 1.25rem;
}

.our-services p {
  font-size: 1rem;
  margin-bottom: 0.9375rem;
}

.our-services .review-trustpilot {
  font-size: 1.125rem;
  font-weight: 500;
  border: solid 0.0625rem var(--bs-secondary);
  padding: 0.5rem 0.9375rem;
  border-radius: var(--border-radius);
  display: inline-block;
}

.our-services .review-trustpilot svg {
  width: auto;
  height: 1.375rem;
  margin-top: -0.375rem;
  display: inline-block;
  margin-left: 0.4375rem;
}

.our-services .services-info {
  padding: 1.25rem;
  border-radius: var(--border-radius);
  background-color: var(--bs-secondary);
  border: solid 0.0625rem var(--bs-secondary);
  margin-top: 3.125rem;
  color: var(--bs-white);
  position: relative;
}

.our-services .services-info h3 {
  font-size: 2rem;
  margin-bottom: 0.9375rem;
}

.our-services .services-info p {
  font-size: 0.875rem;
  /* margin-right: 50%; */
}

.our-services .services-info img {
  position: absolute;
  top: -5rem;
  right: -2.5rem;
  width: 25.625rem;
  z-index: 2;
}

.our-services .info-transparent img {
  width: 42rem;
  right: -10rem;
  top: 0rem;
}

.our-services .services-info .btn-outline-light {
  font-size: 0.875rem;
  padding: 0.75rem 1.75rem 0.6875rem 1.75rem;
  margin: 0;
  z-index: 5;
  position: relative;
}

.our-services .info-transparent {
  background-color: transparent;
  border: solid 0.0625rem var(--bs-secondary);
  color: var(--bs-secondary);
}

.our-services .info-transparent .btn-outline-light {
  border: solid 0.0625rem var(--bs-secondary) !important;
  color: var(--bs-secondary);
  background: transparent;
}

.our-services .info-transparent .btn-outline-light:hover,
.our-services .info-transparent .btn-outline-light:focus {
  background: var(--bs-secondary) !important;
  border: solid 0.0625rem var(--bs-secondary) !important;
  color: var(--bs-white) !important;
}

.Our-Safety {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 31.25rem;
  background-image: url(../images/safety-bg.webp);
  background-position: center top;
  background-repeat: no-repeat;
  /* bad for mobile... */
  /* background-size: 100% auto; */
}

.Our-Safety h2 {
  font-size: 3.75rem;
  margin-bottom: 0.625rem;
  color: var(--bs-primary);
}

.Our-Safety p {
  font-size: 1.25rem;
  margin-bottom: 0.9375rem;
}

.Our-Safety .btn-primary {
  color: var(--bs-secondary);
  margin: 0;
}

.Our-Safety .Safety-card {
  padding: 1.25rem;
  border-radius: var(--border-radius);
  color: var(--bs-white);
  position: relative;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  backdrop-filter: blur(4px);
}

.Our-Safety .Safety-card h3 {
  font-size: 1.375rem;
  margin-bottom: 0.625rem;
}

.Our-Safety .Safety-card p {
  font-size: 0.875rem;
  margin: 0;
}

.Our-Safety .Safety-card img {
  width: 5.3125rem;
  height: 5.3125rem;
  position: absolute;
  top: -3.125rem;
  left: -1.4375rem;
}

.Our-Safety .Safety-01,
.Our-Safety .Safety-02,
.Our-Safety .Safety-03 {
  position: absolute;
  width: 20rem;
}

.Our-Safety .Safety-01 {
  top: 8.125rem;
  right: 16.25rem;
}

.Our-Safety .Safety-01 .Safety-card {
  padding-top: 2.8125rem;
}

.Our-Safety .Safety-02 {
  top: 20.0625rem;
  right: 1.875rem;
}

.Our-Safety .Safety-02 .Safety-card img {
  width: 6.5625rem;
  height: 6.5625rem;
  position: absolute;
  top: -3.125rem;
  left: auto;
  right: -1.875rem;
}

.Our-Safety .Safety-03 {
  top: 23.125rem;
  right: 28.75rem;
}

.Our-Safety .Safety-03 .Safety-card {
  padding-right: 3.9375rem;
}

.Our-Safety .Safety-02 .Safety-card {
  padding-right: 3.9375rem;
}

.Our-Safety .position-relative {
  padding-top: 15.625rem;
  padding-bottom: 15.625rem;
}

.Our-Safety .Safety-03 .Safety-card img {
  width: 6.25rem;
  height: 6.25rem;
  top: auto;
  left: auto;
  right: -1.875rem;
  bottom: -1.5625rem;
}

.purchase-flow,
.legal-sec {
  width: 100%;
  position: relative;
  padding: 3.75rem 0 3.75rem 0;
}

.d-flex-flow {
  display: flex;
  align-items: center;
}

.purchase-flow h2,
.Eff-key-light h2,
.legal-sec h2 {
  font-size: 3.75rem;
  margin-bottom: 0.625rem;
  color: var(--bs-primary);
}

.purchase-flow p,
.Eff-key-light p,
.legal-sec p {
  font-size: 1rem;
  margin-bottom: 0.9375rem;
}

.purchase-flow .nav-link {
  color: var(--bs-primary);
  font-size: 1.5625rem;
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  width: 21.25rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border-radius: var(--border-radius);
  background: #171e3c;
  color: var(--bs-white);
}

.purchase-flow .nav-link span {
  font-size: 0.875rem;
  display: block;
  font-family: var(--bs-body-font-family);
  font-weight: normal;
}

.purchase-flow .nav-pills .nav-link.active,
.purchase-flow .nav-pills .show > .nav-link {
  color: var(--bs-secondary);
  box-shadow: none;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
}

.purchase-flow .tab-content .purchase-content {
  padding: 0;
  position: relative;
}

.navpills {
  width: 1.875rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -3.125rem;
  margin: auto;
  display: inline-table;
}

.navpills .circle {
  background: rgba(244, 222, 147, 0.3);
  height: 0.8125rem;
  width: 0.8125rem;
  border-radius: 50%;
  margin: 0.75rem 0;
}

.tab-content > .active .navpills .circle.active {
  background-color: var(--bs-primary);
}

.purchase-flow .tab-content .purchase-content img {
  margin: auto;
  display: block;
  width: 90%;
  height: auto;
}

.purchase-flow .tab-content {
  margin: auto;
}

.security-privacy {
  padding: 3.75rem 0 3.75rem 0;
}

.security-privacy p.sm-text {
  width: 45%;
  display: block;
  margin: auto;
}

.security-privacy .services-info h3 {
  font-size: 1.5rem;
}

.security-privacy .services-info img {
  position: absolute;
  bottom: -1.5625rem;
  top: auto;
  width: 8.75rem;
  height: 8.75rem;
  right: -1.875rem;
}

.security-privacy .services-info p {
  font-size: 0.875rem;
  /* margin-right: 5.625rem; */
}

.security-privacy .btn-outline-light {
  border: solid 0.0625rem var(--bs-secondary) !important;
  color: var(--bs-secondary);
  background: transparent;
  margin: 1.875rem auto 0rem auto;
  display: block;
  font-size: 1rem;
  padding: 0.875rem 2rem;
}

.security-privacy .btn-outline-light:hover,
.security-privacy .btn-outline-light:focus {
  color: var(--bs-white);
  background: var(--bs-secondary);
}

.faq-section {
  width: 100%;
  position: relative;
  padding: 3.75rem 0 3.75rem 0;
  background-image: url(../images/faq-bg-001.webp);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.faq-section h2,
.stopn-hesitating h2 {
  font-size: 3.75rem;
  /* margin-bottom: 0.625rem; */
  color: var(--bs-primary);
}

.faq-section p {
  font-size: 1rem;
  margin-bottom: 0.9375rem;
}

.faq-section .accordion-item {
  border: none;
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  margin-bottom: 0.9375rem;
}

.faq-section .accordion-button {
  font-size: 1.25rem;
  border-top: solid 0.0625rem #1c1c1c;
  outline: none !important;
  box-shadow: none !important;
  color: var(--bs-white);
  background-color: transparent;
  font-family: var(--bs-font-menu);
}

.faq-section .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFD956'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

/* not sure if needed
 .booster-orders-section {
  width: 100%;
  position: relative;
}  */

.booster-orders-section .accordion-header {
  /* padding: 0; */
  margin: 0;
  font-size: 10px;
}

.booster-orders-section .accordion-item {
  border: none;
  border-radius: var(--border-radius);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  margin-bottom: 0.9375rem;
}

.booster-orders-section .accordion-button {
  outline: none !important;
  box-shadow: none !important;
  color: var(--bs-white);
  background-color: transparent;
  font-family: var(--bs-font-menu);
  /* padding-left: 0px;
  padding-bottom: 0px; */
  padding: 0px;
}

.booster-orders-section .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFD956'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.stopn-hesitating {
  width: 100%;
  position: relative;
  padding: 0;
  background-image: url(../images/stop-h-img.webp);
  background-position: center top;
  background-size: cover;
}

.stopn-hesitating .img-r {
  margin-left: auto;
  display: block;
}

.stopn-hesitating img {
  width: auto;
  height: 26.875rem;
}

.stopn-hesitating p {
  font-size: 1.25rem;
}

.stopn-hesitating .btn-primary {
  color: var(--bs-secondary);
  font-size: 1rem;
  padding: 1rem 2rem;
  margin: 0 0.625rem;
}

.Discord {
  background-color: #5865f2;
  padding: 0.625rem 0;
  /* letter-spacing: 0.0625rem; */
}

.Discord a,
.Discord a:hover {
  color: #ffff;
  text-decoration: underline;
}

.Discord svg {
  margin-left: 0.5rem;
}

footer {
  padding: 2.5rem 0 1.875rem;
  position: relative;
  box-shadow: none;
  color: var(--bs-secondary);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
}

footer h4 {
  font-size: 1.125rem;
  text-align: left;
  margin: 0;
  font-weight: 600;
}

footer p {
  margin-bottom: 0.3125rem;
  font-size: 0.8125rem;
}

footer ul {
  padding: 0;
  margin: 0;
  padding-top: 1.5625rem;
}

footer li {
  list-style: none;
  line-height: 1;
  margin-bottom: 1.25rem;
}

footer li a {
  color: var(--bs-secondary);
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  font-family: var(--bs-body-font-family);
  font-weight: 500;
  font-size: 0.875rem;
}

footer li a:after {
  content: '';
  position: absolute;
  bottom: -0.1875rem;
  left: 0;
  width: 0%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  height: 0.0625rem;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  background-color: var(--bs-secondary);
}

footer li a:hover {
  color: var(--bs-secondary);
  text-decoration: none;
}

footer li a:hover:after {
  width: 100%;
}

footer .copyright {
  padding: 1.875rem 0.9375rem 0;
  text-align: center;
  font-size: 0.875rem;
  overflow: hidden;
  color: gray;
}

footer .copyright div {
  display: inline-block;
  position: relative;
  padding: 0rem 3.125rem;
}

footer .copyright div:before,
footer .copyright div:after {
  content: '';
  display: block;
  position: absolute;
  width: 62.4375rem;
  border-top: 0.0625rem solid var(--bs-secondary);
  height: 0.0625rem;
  top: 50%;
  margin-top: 0;
}

footer .copyright div:before {
  right: 100%;
}

footer .copyright div:after {
  left: 100%;
}

footer .copyright a,
footer .copyright a:hover {
  color: gray;
  text-decoration: underline;
  margin: 0 0.625rem;
}

footer .pay-icon {
  float: left;
}

footer .pay-icon img {
  width: auto;
  height: 1.375rem;
  margin: 0.5rem 0.5rem 0.5rem 0rem;
}

footer .F-social {
  position: relative;
  padding-top: 1.5625rem;
}

footer .F-social a {
  display: inline-block;
  margin: 0 1.25rem 1.25rem 0rem;
  opacity: 0.8;
}

footer .F-social a:hover {
  opacity: 1;
}

footer .F-social a img {
  width: 1.375rem;
  height: 1.375rem;
}

.owl-theme .owl-dots .owl-dot span {
  background: rgba(244, 222, 147, 0.3) !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--bs-primary) !important;
}

.review-sec .owl-carousel.owl-drag .owl-item {
  padding-right: 1.5625rem;
}

.review-card {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  padding: 1.25rem;
  width: 100%;
}

.review-card .logo-trustpilot {
  padding-bottom: 1.25rem;
}

.review-card .logo-trustpilot svg,
.review-card .logo-trustpilot img {
  width: auto;
  height: 1.375rem;
  fill: #fff;
}

.review-card .review-stars img {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.1875rem;
  display: inline-block;
}

.review-info {
  padding-top: 0.625rem;
}

.review-info h3 {
  font-size: 1.5625rem;
  margin-top: 1.25rem;
  margin-bottom: 0.3125rem;
}

.review-info p {
  font-size: 0.875rem;
  margin: 0;
}

.mtb-auto {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 600;
}

.none-sc {
  overflow: hidden;
}

.none-sc .navbar-brand {
  z-index: 5;
}

.none-sc .navbar-brand img {
  height: 2.1875rem;
  transition: all 0.4s ease-in-out;
}

.none-sc .navbar-toggler {
  z-index: 5;
  background-color: var(--bs-secondary);
  transition: all 0.4s ease-in-out;
}

.navbar-expand-lg .navbar-nav .nav-link,
.navbar-nav .dropdown .dropdown-menu {
  margin: 0;
}

.top-h-section {
  position: fixed;
  z-index: 200;
  padding: 0.625rem;
  width: 100%;
  height: 5.3125rem;
  transition: all 0.4s ease-in-out;
}

.navbar-brand,
.navbar-brand:hover {
  padding: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1.875rem;
  color: var(--bs-white);
}

.navbar-brand span {
  color: var(--bs-primary);
}

.navbar-brand img {
  width: auto;
  height: 2.625rem;
  transition: all 0.4s ease-in-out;
}

.navbar-expand-lg {
  padding: 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
  /* padding: 0.625rem 1.125rem;
  margin-top: 0.1875rem; */
  color: var(--bs-white);
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: var(--bs-primary);
}

.navbar-expand-lg .navbar-nav .nav-item {
  margin: 0 0.3125rem;
}

.navbar-expand-lg .navbar-nav {
  margin-left: auto;
  font-family: var(--bs-font-menu);
}

.navbar-nav .dropdown .dropdown-menu {
  border-radius: var(--border-radius);
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-nav .dropdown .dropdown-menu .dropdown-item {
  font-size: 0.869rem;
  margin: 0;
  width: 100%;
  padding: 0.625rem 1.125rem;
  transition: all 0.3s ease;
  border-bottom: none;
  position: relative;
  text-decoration: none;
  background-color: transparent !important;
  background-color: var(--bs-white);
  background: -webkit-linear-gradient(45deg, #fffbd9, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-nav .dropdown .dropdown-menu li + li {
  border-top: solid 0.0625rem #414150;
}

.navbar-nav .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--bs-primary);
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdown-toggle:after {
  transition: all 0.23s ease;
}

.dropdown-toggle:hover:after {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}

.dropdown-menu-end {
  right: 0;
}

.navbar-expand-lg .navbar-nav .nav-item .client-area-link {
  color: var(--bs-secondary);
  border-style: solid;
  padding: 0.75rem 1.25rem;
  border-radius: var(--border-radius);
  margin-top: 0;
  font-size: 0.9375rem;
  border: solid 0.0625rem var(--bs-secondary-rgb);
  box-shadow: none;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  font-family: var(--bs-font-medium) !important;
}

.navbar-expand-lg .navbar-nav .nav-item .join-discord-link {
  color: #fff;
  background-color: #5865f2;
  border: solid 0.0625rem #5865f2;
  box-shadow: none;
  padding: 0.75rem 1.25rem;
  border-radius: var(--border-radius);
  margin-top: 0;
  margin-right: 0.625rem;
  font-size: 0.9375rem;
  font-family: var(--bs-font-medium) !important;
}

.navbar-expand-lg .navbar-nav .nav-item .join-discord-link svg {
  margin-top: -0.25rem;
  margin-bottom: -0.125rem;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3125rem;
}

.navbar-toggler {
  background-color: var(--bs-primary);
  border-radius: var(--border-radius);
  padding: 0;
  transition: all 0.4s ease-in-out;
  margin-right: 0.9375rem;
  box-shadow: none !important;
}

.icon-container {
  position: relative;
  display: inline-block;
  z-index: 2;
  float: right;
  height: 2.0625rem;
  width: 2.3125rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

.icon-container #menuicon {
  width: 1.25rem;
  height: 0.625rem;
  position: relative;
  display: block;
  margin: -0.25rem auto 0;
  top: 50%;
}

#menuicon .bar {
  width: 100%;
  height: 0.0625rem;
  display: block;
  position: relative;
  background: var(--bs-secondary);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

#menuicon .bar.bar1 {
  -webkit-transform: translateY(0rem) rotate(0deg);
  transform: translateY(0rem) rotate(0deg);
}

#menuicon .bar.bar2 {
  -webkit-transform: translateY(0.375rem) rotate(0deg);
  transform: translateY(0.375rem) rotate(0deg);
}

.none-sc .icon-container {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.none-sc .icon-container #menuicon .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.1s;
}

.none-sc .icon-container #menuicon .bar.bar1 {
  -webkit-transform: translateY(0.25rem) rotate(45deg);
  transform: translateY(0.25rem) rotate(45deg);
  background-color: var(--bs-white);
}

.none-sc .icon-container #menuicon .bar.bar2 {
  -webkit-transform: translateY(0.1875rem) rotate(-45deg);
  transform: translateY(0.1875rem) rotate(-45deg);
  background-color: var(--bs-white);
}

.sc-down .top-h-section {
  margin-top: -6.875rem;
}

.sc-up .top-h-section {
  margin-top: 0;
  background-color: rgba(23, 22, 48, 0.85);
  height: 3.4375rem;
  padding: 0.3125rem 0;
  backdrop-filter: blur(4px);
}

.sc-up .navbar-brand img {
  height: 2.125rem;
}

.review-trustpilot-bar {
  width: 100%;
  padding: 0.625rem 0;
  color: var(--bs-secondary);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.review-trustpilot-bar .review-trustpilot svg {
  width: auto;
  height: 1.25rem;
  margin-top: -0.25rem;
  display: inline-block;
  margin-left: 0.4375rem;
}

.division-tabs {
  /* width: 100vw; */
  position: relative;
  padding: 3.75rem 0 3.75rem 0;
  /* background-image: url(../images/bg-image-tabs.webp); */
  background-position: center top;
  background-repeat: no-repeat;
  /* bad for mobile...
  background-size: 100% auto; */
}

.division-tabs a {
  color: var(--bs-primary);
  text-decoration: underline;
}

.division-tabs .nav-pills {
  margin: auto;
  width: fit-content;
  padding-left: 0;
  border: solid 0.0625rem rgba(255, 225, 255, 0.5);
  border-radius: 1.875rem;
  margin-bottom: 1rem;
}

.division-tabs .nav-pills .nav-link {
  color: var(--bs-white);
  font-size: 1rem;
  padding: 0.75rem 2rem;
  border-radius: 1.875rem;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
}

.division-tabs .nav-pills .nav-link.active {
  color: var(--bs-secondary);
  font-size: 1rem;
  padding: 0.75rem 2rem;
  border-radius: 1.875rem;
  margin: 0rem;
  font-family: var(--bs-font-sans-serif);
}

.my-animation-fast {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}

.my-animation-slow {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 1000ms;
}

.my-height-animation.is-open {
  grid-template-rows: 1fr;
}

.my-height-animation > div {
  overflow: hidden;
}

.q-start {
  padding: 1.25rem;
  border-radius: var(--border-radius);
  color: var(--bs-white);
  position: relative;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  backdrop-filter: blur(4px);
  border: solid 0.0625rem rgba(var(--bs-primary-rgb), 0.5);
  margin-bottom: 12px;
}

.qs-bg {
  /* background-image: url(../images/q-start-bg.webp); */
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #1f2140;
}

.checkout-rs {
  padding: 1.25rem;
  border-radius: var(--border-radius);
  color: var(--bs-white);
  position: relative;
  background-color: rgb(34, 33, 70, 0.85);
  border: solid 0.0625rem rgba(var(--bs-primary-rgb), 0.5);
  height: 100%;
  display: grid;
}

.q-start-bg {
  background: rgba(34, 33, 70, 0.65);
  margin-bottom: 1px;
}

.q-start h2,
.checkout-rs h2 {
  font-size: 1.875rem;
  margin-bottom: 0.375rem;
}

.checkout-rs h2 {
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: auto;
  text-align: center;
  margin-bottom: 1.5625rem;
  font-size: 2.5rem;
}

.q-start p {
  margin-bottom: 0.625rem;
  font-size: 1rem;
}

.q-start .btn-continue {
  font-size: 1rem;
  padding: 0.75rem 1.5625rem;
}

.q-start .icon-rd {
  margin-right: 0.9375rem;
  padding: 0.625rem;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  backdrop-filter: blur(4px);
  border-radius: var(--border-radius);
  height: 60px;
}

.q-start .icon-rd img {
  width: 3.125rem;
  height: 3.125rem;
}

.btn-check-icon {
}

.btn-check-icon .btn-outline-primary {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  margin-bottom: 0.9375rem;
  padding: 0.3125rem !important;
  border-radius: var(--border-radius);
  margin-right: 0.625rem;
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  font-size: 1.25rem !important;
}

.btn-check-icon .btn-outline-primary img {
  width: 2.8125rem;
  height: 2.8125rem;
}

.btn-check-icon .btn-check:active + .btn-outline-primary,
.btn-check-icon .btn-check:checked + .btn-outline-primary,
.btn-check-icon .btn-outline-primary.active,
.btn-check-icon .btn-outline-primary.dropdown-toggle.show,
.btn-check-icon .btn-outline-primary:active,
.btn-check-icon .btn-outline-primary:hover {
  border-radius: var(--border-radius);
  margin-bottom: 0.9375rem;
  padding: 0.3125rem;
  border-radius: var(--border-radius);
  /* margin-right: 0.625rem; */
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
}

.btn-check-icon .btn-group > .btn-group:not(:last-child) > .btn,
.btn-check-icon .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-check-icon .btn-group > .btn-group:not(:first-child) > .btn,
.btn-check-icon .btn-group > .btn:nth-child(n + 3),
.btn-check-icon .btn-group > :not(.btn-check) + .btn {
  border-radius: var(--border-radius);
}

.check-division .btn-outline-primary {
  padding: 0.625rem !important;
  min-width: 2.8125rem;
}

.check-division .btn-check:active + .btn-outline-primary,
.check-division .btn-check:checked + .btn-outline-primary,
.check-division .btn-outline-primary.active,
.check-division .btn-outline-primary.dropdown-toggle.show,
.check-division .btn-outline-primary:active,
.check-division .btn-outline-primary:hover {
  padding: 0.625rem;
  min-width: 2.8125rem;
  color: var(--bs-secondary);
}

.division-tabs .tab-pane {
  width: 80%;
  margin: auto;
}

.checkout-rs .silver-icon,
.checkout-rs .gold-icon {
  text-align: center;
  font-size: 1rem;
}

.checkout-rs .gold-icon {
  color: var(--bs-primary);
}

.checkout-rs .gold-icon span,
.q-start .icon-rd-value {
  font-family: var(--bs-font-sans-serif);
  font-size: 5rem;
  line-height: 4.6875rem;
  display: block;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gold-icon span {
  font-family: var(--bs-font-sans-serif);
  font-size: 5rem;
  line-height: 4.6875rem;
  display: block;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-rd-value {
  margin-right: 0.625rem;
}

.checkout-rs .silver-icon img,
.checkout-rs .gold-icon img {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.3125rem;
  border-radius: var(--border-radius);
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  width: max-content;
  width: 4.0625rem;
  height: 4.0625rem;
  margin: auto;
  display: block;
  margin-bottom: 0.625rem;
}

.checkout-rs .gold-icon img {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  border: solid 0.0625rem var(--bs-primary);
}

.checkout-rs .arr-right {
  margin: auto;
  width: 2rem;
  height: 2rem;
  margin-top: 0.8125rem;
}

.checkout-list {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0.5rem 0;
  font-weight: bold;
  margin-top: 0.9375rem;
}

.checkout-list + .checkout-list {
  border-top: solid 0.0625rem #494743;
  margin-top: 0;
}

.checkout-list .bi-info-circle,
.q-start .bi-info-circle {
  color: var(--bs-primary);
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.3125rem;
  margin-top: -0.125rem;
}

.checkout-list .text-lab,
.text-lab {
  padding: 0.25rem 0.4375rem;
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1;
  color: var(--bs-primary);
  border: solid 0.0625rem var(--bs-primary);
  line-height: 1;
  border-radius: 0.5rem;
  letter-spacing: normal;
  text-align: center;
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  min-width: 3.25rem;
  margin-right: 0.9375rem;
}

.checkout-list .text-lab-green,
.text-lab-green {
  color: #00b67a;
  border: solid 0.0625rem #00b67a;
  background-color: rgba(0, 182, 122, 0.2);
}

.checkout-list .list-title {
  font-size: 1rem;
  font-weight: bold;
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
  padding-right: 0.9375rem;
}

.promocode {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.total-amount {
  font-weight: bold;
  margin-top: 0.625rem;
  margin-bottom: 0.9375rem;
  font-size: 1.125rem;
}

.total-amount .amount {
  font-family: var(--bs-font-sans-serif);
  text-align: right;
  font-size: 1.25rem;
}

.total-amount .amount span {
  display: block;
  font-size: 0.8125rem;
  color: var(--bs-primary);
}

.checkout-review {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.checkout-review svg {
  width: auto;
  height: 1.25rem;
  margin-top: -0.25rem;
  display: inline-block;
  margin-left: 0.4375rem;
  fill: #fff;
}

.checkout-rs .buy-now {
  /* padding-top: 0.625rem; */
  text-align: center;
}

.checkout-rs .btn-buy-now,
.chatnow {
  font-size: 1rem;
  padding: 0.75rem 1.5625rem;
  /* width: 100%; */
  margin-bottom: 0.625rem;
}

.chatnow {
  /* color: var(--bs-primary); */
  margin-top: 0.125rem;
}

.chatnow svg {
  fill: var(--bs-primary);
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.625rem;
}

.chatnow:hover svg {
  fill: var(--bs-secondary);
}

.premium-features {
  width: 100%;
  position: relative;
  padding: 3.75rem 0 1.875rem 0;
  color: var(--bs-secondary);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(180deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(180deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  overflow: hidden;
}

.premium-features .features-card {
  padding: 1.5625rem;
  border-radius: var(--border-radius);
  background-color: var(--bs-secondary);
  border: solid 0.0625rem var(--bs-secondary);
  color: var(--bs-white);
  position: relative;
  margin-bottom: 1.875rem;
}

.premium-features .features-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.9375rem;
}

.premium-features .features-card p {
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-right: 2.1875rem;
}

.premium-features .features-card .pf-icon {
  background-color: #22242f;
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 50%;
  display: block;
  margin-bottom: 0.9375rem;
  display: flex;
  padding: 0.1875rem;
}

.premium-features .features-card img {
  height: 2rem;
  width: 2rem;
  margin: auto;
}

.q-start .form-check-label {
  font-size: 1rem;
}

.radio-btn .form-check-label {
  padding-top: 0.125rem;
}

.form-check .form-check-input {
  margin-right: 0.625rem;
}

.range-label {
  font-size: 0.8125rem;
  display: block;
  margin-bottom: 0.9375rem;
}

.form-range {
  width: 100%;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.0625rem var(--bs-primary), 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.3);
  background-color: var(--bs-primary);
}

.form-range::-webkit-slider-runnable-track {
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 0.0625rem var(--bs-primary), 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.2);
  background-color: var(--bs-primary);
}

.form-range::-moz-focus-outer {
  border: 0.1875rem;
}

.form-range::-webkit-slider-thumb {
  width: 1.3rem;
  height: 1.3rem;
  margin-top: -0.35rem;
  background-color: var(--bs-primary);
  border-radius: 1.3rem;
}

.q-start .progress {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.q-start .progress-bar {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  border-radius: 16px;
}

.q-start .progress {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  border-radius: 16px;
}

.accounts {
  width: 100%;
  position: relative;
  padding: 3.75rem 0 3.75rem 0;
}

.accounts .left-filters .accordion-button:not(.collapsed) {
  color: var(--bs-white);
  background-color: transparent;
  box-shadow: none;
}

.accounts .left-filters .accordion-item {
  background-color: transparent;
}

.accounts .left-filters .accordion-button {
  padding: 0.75rem 0.625rem 0.75rem 0rem;
  font-size: 1.125rem;
  color: var(--bs-white);
  background-color: transparent;
  outline: none;
  box-shadow: none;
  font-family: var(--bs-font-sans-serif);
}

.accounts .left-filters .accordion-body {
  padding: 0;
}

.accounts .left-filters .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFD956'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accounts .left-filters .form-check {
  position: relative;
  display: block;
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
}

.accounts .left-filters .form-check:hover {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  backdrop-filter: blur(4px);
}

.accounts .left-filters .form-check-input {
  margin-left: 0;
  margin-top: 0.125rem;
}

.accounts .left-filters .accordion-item + .accordion-item {
  border-top: 0.0625rem solid #5d5d6e;
}

.accounts .search {
  width: 60%;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  display: flex;
}

.accounts .search svg {
  position: absolute;
  top: 0.5625rem;
  left: 0.875rem;
  height: 1.25rem;
  width: 1.25rem;
}

.accounts .search input,
.accounts .search input:focus {
  /* border-radius: 2.1875rem; */
  height: 2.375rem;
  width: 25.625rem;
  background-color: transparent;
  border: solid 0.0625rem #fff;
  padding: 0.625rem 1.5625rem 0.625rem 2.625rem;
  color: #fff;
}

.sort-select select {
  width: 10.625rem;
  border-radius: var(--border-radius);
  border: solid 0.0625rem #c1c1c1 !important;
}

.sort-select:hover select:hover {
  color: #fff !important;
}

.grid-view {
  padding: 0;
}

.grid-view .btn-outline-light.active {
  display: none;
}

.grid-view .btn-outline-light {
  border-radius: 1.875rem;
  padding: 0.625rem 1.125rem;
  height: 2.375rem;
  border: solid 0.0625rem #fff !important;
  display: block;
}

.grid-view .btn-outline-light svg {
  margin-top: -0.1875rem;
  margin-right: 0.3125rem;
  width: 1rem;
  height: 1rem;
}

.right-action {
  padding-bottom: 1.875rem;
}

.shopcard {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    90deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(90deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  padding: 1.25rem;
  width: 100%;
  font-size: 0.875rem;
  margin-bottom: 1.875rem;
}

.shopcard .shopcard-img {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(46, 43, 134, 0.3) 0%, rgba(26, 156, 251, 0.3) 100%);
  background: -webkit-linear-gradient(
    90deg,
    rgba(46, 43, 134, 0.3) 0%,
    rgba(26, 156, 251, 0.3) 100%
  );
  background: linear-gradient(90deg, rgba(46, 43, 134, 0.3) 0%, rgba(26, 156, 251, 0.3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(46, 43, 134, 0.3)", endColorstr="#rgba(26, 156, 251, 0.3)", GradientType=1);
  width: 100%;
}

.shopcard-img > img {
  border-radius: var(--border-radius);
}

.shopcard .shopcard-img img {
  width: 100%;
  margin: auto;
  display: block;
  aspect-ratio: 16/9;
}

.shopcard h2 {
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
}

.shopcard .row-info {
  margin-bottom: 0.9375rem;
}

.shopcard .row-info img {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.5rem;
}

.shopcard .price {
  font-size: 1rem;
}

.shopcard .price span {
  opacity: 0.75;
}

.shopcard .price div {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
  line-height: 1;
}

.shopcard .btn-primary {
  font-size: 1rem;
  padding: 0.9375rem 0.625rem;
  width: 100%;
}

.btn-show,
.btn-show:focus,
.btn-show:hover {
  font-size: 1rem;
  margin: auto;
  display: block;
}

.shop-list-card {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    90deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(90deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  padding: 0.625rem;
  width: 100%;
  font-size: 0.875rem;
  margin-bottom: 1.875rem;
}

.shop-list-card .th-img {
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 0.9375rem;
}

.shop-list-card h2 {
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
}

.shop-list-card .price {
  font-size: 1rem;
  text-align: right;
}

.shop-list-card .price span {
  opacity: 0.75;
}

.shop-list-card .price div {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
  line-height: 1;
  white-space: nowrap;
}

.shop-list-card .row-info {
  border-left: solid 0.0625rem #9092a0;
  border-right: solid 0.0625rem #9092a0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.shop-list-card .row-info img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}

.shop-list-card .btn-primary {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  white-space: nowrap;
}

.Eff-key-light {
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 3.75rem 0 0rem 0;
  color: var(--bs-secondary);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(180deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 217, 86, 1) 0%,
    rgba(255, 251, 217, 1) 100%
  );
  background: linear-gradient(180deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  overflow: hidden;
  color: var(--bs-secondary);
}

.Eff-key-light h2 {
  color: var(--bs-secondary);
}

.Eff-key-light .Off-icon {
  width: 2.8125rem;
  float: left;
}

.Eff-key-light .OE-list-r {
  margin-left: 4.375rem;
}

.Eff-key-light h3 {
  font-size: 1.875rem;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 0.625rem;
  border-bottom: solid 0.0625rem #7f7757;
}

.mb-eff-key {
  margin-bottom: 5rem;
}

.eff-key-img {
}

.eff-key-img img {
  width: auto;
  height: 33.75rem;
  display: block;
  margin-left: auto;
}

.Eff-key-dark {
  background: var(--bs-secondary) !important;
  color: #8b8a97;
}

.Eff-key-dark h2,
.Eff-key-dark h3 {
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.division-tabs .nav-pills .nav-link.active img,
.division-tabs .nav-pills .nav-link img {
  float: right;
  width: 1.375rem;
  height: 1.375rem;
  margin-left: 0.625rem;
  filter: invert(1);
}

.division-tabs .nav-pills .nav-link.active img {
  filter: none;
}

.pack {
  border-radius: 0.9375rem;
  color: var(--bs-white);
  position: relative;
  overflow: hidden;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.2) 0%,
    rgba(26, 156, 251, 0.15) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.2) 0%,
    rgba(26, 156, 251, 0.15) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.2) 0%, rgba(26, 156, 251, 0.15) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.20)", endColorstr="#rgba(26,156,251,0.15)", GradientType=1);
  margin: 0.3125rem;
}

.pack .header-pack {
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  display: flex;
  color: var(--bs-secondary);
  position: relative;
  padding: 0.9375rem 0.625rem;
}

.pack .header-pack img {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background-color: var(--bs-secondary);
  margin-right: 0.9375rem;
}

.pack .header-pack h3 {
  font-size: 1.875rem;
  margin-bottom: 0;
  line-height: 1;
}

.pack .header-pack span {
  display: block;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1;
}

.pack .header-pack .pack-type {
  background-color: var(--bs-white);
  font-size: 1rem;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  border-radius: 1.375rem;
  padding: 0.375rem 0.9375rem 0.3125rem 0.9375rem;
}

.pack .header-pack .pack-h {
  margin-top: auto;
  margin-bottom: auto;
}

.pack .packlist {
  padding: 1.25rem 1.25rem 0rem 1.25rem;
}

.pack .packlist span {
  padding-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 1rem;
  display: block;
  font-family: var(--bs-font-sans-serif);
  /* letter-spacing: 0.0625rem; */
  text-transform: uppercase;
}

.pack .packlist span svg {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.75rem;
  color: var(--bs-primary);
}

.pack .price-pack {
  font-family: var(--bs-font-sans-serif);
  font-size: 3.75rem;
  line-height: 1;
  display: table;
  margin: auto;
}

.pack .price-pack span {
  font-size: 0.6875rem;
  color: var(--bs-primary);
  display: block;
  text-align: right;
}

.pack .btn-primary {
  font-size: 1rem;
  margin: 1.25rem;
  width: calc(100% - 2.5rem);
  display: table;
}

.premium-pack {
  border-radius: 0.9375rem;
  color: var(--bs-white);
  position: relative;
  overflow: hidden;
  background: rgba(var(--bs-primary-rgb), 0.1);
  border: solid 0.0625rem var(--bs-primary);
  padding: 1.875rem;
  margin-top: 1.875rem;
  color: #8b8a97;
}

.premium-pack h3 {
  font-size: 1.875rem;
  margin-bottom: 0.9375rem;
  color: #fff;
}

.premium-pack h3 svg {
  width: 1.4375rem;
  height: 1.4375rem;
  margin-top: -0.375rem;
  margin-left: 0.625rem;
}

.premium-pack p {
  font-size: 1rem;
  margin-bottom: 0;
}

.premium-pack h4.lab-premium {
  color: var(--bs-secondary);
  background-color: var(--bs-white);
  font-size: 0.875rem;
  border-radius: 1.375rem;
  padding: 0.375rem 0.9375rem 0.3125rem 0.9375rem;
  width: fit-content;
  display: block;
}

.premium-pack .pp-img {
  width: auto;
  height: 7.5rem;
  margin-top: 0.9375rem;
  margin-right: 0.9375rem;
}

.premium-pack .pp-info {
  padding-top: 0.9375rem;
  font-size: 1rem;
  display: block;
  font-family: var(--bs-font-sans-serif);
  /* letter-spacing: 0.0625rem; */
  text-transform: uppercase;
  color: #fff;
}

.premium-pack .pp-info svg {
  width: 1.375rem;
  height: 1.375rem;
  margin-top: -0.1875rem;
  margin-right: 0.75rem;
  color: var(--bs-primary);
}

.premium-pack .price-p-pack {
  font-family: var(--bs-font-sans-serif);
  font-size: 3.4375rem;
  line-height: 1;
  display: table;
  margin: auto;
  color: var(--bs-white);
  text-align: center;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  padding-bottom: 1.25rem;
}

.premium-pack .price-p-pack span {
  font-size: 0.6875rem;
  color: var(--bs-primary);
  display: block;
  text-align: right;
}

.premium-pack .btn-primary {
  font-size: 1rem;
  width: calc(100%);
  display: table;
}

.pp-lb {
  border-left: solid 0.0625rem rgba(var(--bs-primary-rgb), 0.5);
  padding-left: 1.5625rem;
}

.bg-none {
  background-image: none !important;
}

.realtime-sp {
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 0;
}

.realtime-sp h2 {
  font-size: 3.75rem;
  margin-bottom: 1.25rem;
}

.realtime-sp p {
  font-size: 1rem;
  margin-bottom: 1.25rem;
  color: #95959d;
}

.realtime-sp span {
  color: var(--bs-primary);
  display: block;
  font-size: 0.875rem;
}

.realtime-sp .btn-primary svg,
.contact-features svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}

.realtime-sp .leftimg {
  margin: auto;
}

.realtime-sp .leftimg img {
  width: auto;
  height: 37.5rem;
}

.contact-features {
  text-align: center;
  margin: auto;
}

.contact-features span {
  font-size: 0.875rem;
}

.contact-features h2 {
  border-bottom: solid 0.0625rem var(--bs-secondary);
  padding-bottom: 1.25rem;
}

.contact-features .features-card {
  text-align: left;
}

.contact-features .features-card p {
  color: #95959d;
  margin-bottom: 1.25rem;
}

.contact-form-sec {
  padding: 3.75rem 0 3.75rem 0;
  background-image: url(../images/contact-form-bg.webp);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 28.125rem;
}

.contact-form-sec h2 {
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-form-sec p {
  width: 55%;
}

.contact-form-sec .contact-form {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  padding: 1.875rem;
  backdrop-filter: blur(16px);
}

.contact-form-sec .form-control,
.contact-form-sec .form-control:focus {
  background-color: transparent !important;
  border: solid 0.0625rem #fff;
}

.contact-form-sec .container {
  max-width: 71.25rem;
}

.legal-sec {
  padding-bottom: 0;
  overflow: hidden;
}

.legal-sec span {
  color: #878ba8;
}

.legal-sec p {
  color: #95959d;
  margin-bottom: 1.875rem;
}

.legal-sec h5 {
  font-size: 1rem;
  color: var(--bs-primary);
}

.legal-sec h5 svg {
  margin-top: -0.1875rem;
  margin-right: 0.5rem;
}

.legal-sec ul {
  padding: 0;
  margin: 0;
  padding-left: 1rem;
}

.legal-sec ul li {
}

.legal-sec ul li a {
  color: var(--bs-white);
  font-size: 0.875rem;
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  border-radius: var(--border-radius);
  text-decoration: none;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
}

.legal-sec ul li a:hover,
.legal-sec ul li a.active {
  background: var(--bs-primary);
  color: var(--bs-secondary);
}

.legal-sec ul li a svg {
  width: 1.125rem;
  height: 1.125rem;
}

.legal-sec .legal-info {
  padding: 1.875rem;
  font-size: 0.875rem;
  border-radius: var(--border-radius);
  text-decoration: none;
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
}

.legal-sec .legal-info h3 {
  margin-bottom: 0.9375rem;
}

.legal-sec .legal-info .w-label {
  color: var(--bs-secondary);
  background-color: var(--bs-white);
  font-size: 0.875rem;
  border-radius: 1.375rem;
  padding: 0.375rem 0.9375rem 0.3125rem 0.9375rem;
  width: fit-content;
  display: block;
  margin-bottom: 0.9375rem;
}

.legal-sec .legal-info p {
  color: var(--bs-white);
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.legal-sec .dis-btn {
  margin: auto;
  display: block;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.legal-sec .legal-info .b-info img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.legal-sec .container {
  max-width: 71.25rem;
}

.legal-sec .left-b-img {
  margin-left: -5rem;
  height: 21.875rem;
}

.legal-sec .left-b-img {
  margin-left: -4.375rem;
  height: 20rem;
}

.login .modal-content {
  color: var(--bs-white);
  background-color: #171630;
  background: linear-gradient(#171630, #171630) padding-box,
    linear-gradient(to right, #ffd956, #fffbd9) border-box;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
}

.login .modal-body {
  padding: 1.875rem 2.5rem;
  text-align: left;
}

.login .modal-body h2 {
  font-size: 1.875rem;
}

.login .modal-body p {
  font-size: 1rem;
}

.login .modal-body .btn-primary {
  padding: 0.75rem 0;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  /* margin-top: 0.625rem; */
  color: var(--bs-secondary);
  letter-spacing: 0.0313rem;
}

.login .modal-body .btn-primary svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.login .modal-body .discord-btn {
  color: #fff;
  background: #5865f2;
  border: solid 0.0625rem #5865f2;
}

.login .modal-body .form-control {
  padding: 0.625rem 12px;
}

.login .modal-body .or {
  overflow: hidden;
  text-align: center;
  margin: auto;
  margin-top: 0.625rem;
}

.login .modal-body .or span {
  display: inline-block;
  position: relative;
  padding: 0rem 2.5rem;
  color: #bcadad;
}

.login .modal-body .or span:before,
.login .modal-body .or span:after {
  content: '';
  display: block;
  position: absolute;
  width: 62.4375rem;
  border-top: 0.0625rem solid #747474;
  height: 0.0625rem;
  top: 50%;
  margin-top: 0;
}

.login .modal-body .or span:before {
  right: 100%;
}

.login .modal-body .or span:after {
  left: 100%;
}

.login .modal-body .form-check {
  margin: 0;
}

.login .modal-body .form-check-label {
  font-size: 0.875rem;
  display: flex;
}

.login .modal-body .form-check-input {
  margin-top: 0.0625rem;
}

.login .btn-close {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  filter: invert();
  opacity: 8;
}

.footer-logo {
  margin-bottom: 0.9375rem;
}

.fg-pass {
  font-size: 13px;
  color: #fff;
  opacity: 0.5;
}

.fg-pass:hover {
  color: var(--bs-primary);
  opacity: 1;
}

.footer-logo img {
  width: auto;
  height: 2.5rem;
}

.text-right {
  text-align: right !important;
}

.shimmer {
  padding: 0.3125rem;
  width: 100%;
  margin: 0.625rem auto;
}

.shimmer .image-card {
  height: 5.625rem;
  width: 5.625rem;
  float: right;
  border-radius: 0.5rem;
}

.stroke {
  height: 1.5625rem;
  background: #777;
  margin-top: 1.25rem;
  width: 100%;
}

.c-img-effect {
  height: 12.5rem;
  background: #777;
  margin-top: 0rem;
  width: 100%;
}

.card-in-effect {
  height: 1.125rem;
  background: #fff;
  margin-top: 1.25rem;
  width: 100%;
}

.list-stock {
  height: 3.4375rem;
  background: #777;
  margin-top: 0;
  margin-bottom: 1.875rem !important;
  width: 100%;
  border-radius: 0.5rem !important;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #37333e 5%, #182445 25%, #37333e 35%);
  background-size: 62.5rem 100%;
  border-radius: 0.3125rem;
}

@keyframes shimmer {
  from {
    background-position: -62.5rem 0;
  }

  to {
    background-position: 62.5rem 0;
  }
}

@media (max-width: 90rem) {
  .purchase-flow .tab-content .purchase-content img {
    width: 75%;
  }

  .premium-features .features-card h3 {
    margin-bottom: 0.625rem;
  }

  .stopn-hesitating img {
    height: 24.375rem;
  }
}

@media (max-width: 85.375rem) {
  .Our-Safety .Safety-01 {
    right: 10.625rem;
  }

  .division-tabs .tab-pane {
    width: 100%;
  }

  .checkout-list .list-title {
    font-size: 0.875rem;
  }

  .shopcard {
    padding: 0.75rem;
    font-size: 0.8125rem;
    letter-spacing: normal;
  }

  .shopcard .row-info img {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.1875rem;
  }

  .shopcard h2,
  .shop-list-card h2 {
    font-size: 1.25rem;
  }

  .shopcard .price {
    font-size: 0.875rem;
    padding-right: 0;
  }

  .shopcard .price div,
  .shop-list-card .price div {
    font-size: 1.25rem;
  }

  .shopcard .btn-primary {
    font-size: 0.875rem;
    padding: 0.75rem 0.625rem;
  }

  .shop-list-card .th-img {
    width: 2.8125rem;
    height: 2.8125rem;
    margin-right: 0.5rem;
  }

  .shop-list-card .row-info {
    padding-left: 0;
    padding-right: 0;
  }

  .shop-list-card {
    font-size: 0.8125rem;
  }

  .shop-list-card .price {
    font-size: 0.875rem;
  }

  .shop-list-card .btn-primary {
    padding: 0.875rem 1.25rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 80rem) {
  .header .h-content h1 {
    font-size: 4.375rem;
    line-height: 1;
    margin-bottom: 1.25rem;
  }

  .header .h-content h3 {
    font-size: 1.375rem;
    margin-bottom: 1.5625rem;
  }

  .header .btn-primary,
  .header .btn-outline-light,
  .Our-Safety .btn-primary,
  .stopn-hesitating .btn-primary {
    padding: 0.875rem 1.75rem;
    /* border-radius: 2.1875rem; */
  }

  .our-services h2,
  .Our-Safety h2,
  .Our-Safety h2,
  .premium-features h2,
  .realtime-sp h2 {
    font-size: 3.4375rem;
    margin-bottom: 0.9375rem;
  }

  .our-services .services-info h3 {
    font-size: 1.75rem;
    margin-bottom: 0.625rem;
  }

  .our-services .review-trustpilot {
    font-size: 1rem;
  }

  .Our-Safety p {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }

  .purchase-flow h2,
  .faq-section h2,
  .stopn-hesitating h2,
  .Eff-key-light h2,
  .legal-sec h2 {
    font-size: 3.4375rem;
  }

  .purchase-flow p,
  .Eff-key-light p,
  .realtime-sp p,
  .legal-sec p {
    font-size: 0.875rem;
  }

  .Eff-key-light .OE-list-r {
    margin-left: 3.75rem;
  }

  .purchase-flow .nav-link {
    font-size: 1.375rem;
  }

  .security-privacy .services-info img {
    width: 7.5rem;
    height: 7.5rem;
  }

  .stopn-hesitating p {
    font-size: 1.125rem;
  }

  .realtime-sp .leftimg img {
    height: 33.125rem;
  }

  .stopn-hesitating img {
    height: 21.875rem;
  }
}

@media (max-width: 64rem) {
  .c-img-effect {
    height: 9.375rem;
  }

  .card-in-effect {
    height: 0.875rem;
    margin-top: 0.625rem;
  }

  .login .modal-body h2 {
    font-size: 1.5625rem;
  }

  .login .modal-body p,
  .login .modal-body .form-label {
    font-size: 0.875rem;
  }

  .login .modal-body .btn-primary {
    font-size: 0.875rem;
  }

  .legal-sec .legal-info h3 {
    font-size: 1.5rem;
  }

  .pack {
    margin: 0rem;
  }

  .pack .header-pack h3 {
    font-size: 1.5625rem;
  }

  .pack .header-pack span {
    font-size: 1rem;
  }

  .pack .header-pack img {
    width: 3.125rem;
    height: 3.125rem;
  }

  .pack .header-pack .pack-type,
  .premium-pack .pp-info {
    font-size: 0.875rem;
  }

  .pack .packlist span,
  .premium-pack p {
    font-size: 0.875rem;
  }

  .pack .price-pack {
    font-size: 3.4375rem;
  }

  .eff-key-img img {
    height: 28.75rem;
  }

  .premium-pack .price-p-pack {
    font-size: 3.125rem;
  }

  .premium-pack h3 {
    font-size: 1.625rem;
  }

  .premium-pack {
    padding: 1.25rem;
  }

  .mb-eff-key {
    margin-bottom: 1.875rem;
  }

  .Eff-key-light h3 {
    font-size: 1.5625rem;
  }

  .shop-list-card .price {
    padding-right: 0;
  }

  .shop-list-card {
    font-size: 0.6875rem;
  }

  .shopcard .row-info img {
    display: block;
    margin-bottom: 0.1875rem;
  }

  .header .h-content h1 {
    font-size: 3.75rem;
  }

  .header .h-content h3 {
    font-size: 1.25rem;
    margin-bottom: 1.5625rem;
  }

  .our-services h2,
  .Our-Safety h2,
  .premium-features h2,
  .realtime-sp h2 {
    font-size: 3.125rem;
    margin-bottom: 0.625rem;
  }

  .purchase-flow h2,
  .faq-section h2,
  .stopn-hesitating h2,
  .Eff-key-light h2,
  .legal-sec h2 {
    font-size: 3.125rem;
  }

  .our-services .services-info img {
    width: 23.125rem;
  }

  .security-privacy .services-info img {
    width: 6.875rem;
    height: 6.875rem;
  }

  .security-privacy .services-info p {
    font-size: 0.875rem;
    /* margin-right: 4.625rem; */
  }

  .our-services .info-transparent img {
    width: 42.375rem;
    margin-right: -10rem;
  }

  .our-services {
    padding: 3.75rem 0 11.75rem 0;
  }

  .security-privacy {
    padding: 3.75rem 0 3.75rem 0;
  }

  .our-services .services-info h3 {
    font-size: 1.5rem;
  }

  .Our-Safety .Safety-card img {
    width: 4.6875rem;
    height: 4.6875rem;
    top: -2.5rem;
    left: -2.0625rem;
  }

  .Our-Safety .Safety-02 .Safety-card img {
    width: 5.9375rem;
    height: 5.9375rem;
    top: -1.875rem;
    right: -1.25rem;
  }

  .Our-Safety .Safety-03 .Safety-card img {
    width: 5.625rem;
    height: 5.625rem;
    right: -1.8125rem;
    bottom: -1.5625rem;
  }

  .Our-Safety .Safety-card h3 {
    font-size: 1.25rem;
  }

  .Our-Safety .Safety-01 .Safety-card {
    padding-top: 2.5rem;
  }

  .Our-Safety .Safety-03 .Safety-card {
    padding-right: 3.9375rem;
  }

  .Our-Safety .Safety-01 {
    right: 6.25rem;
  }

  .Our-Safety .Safety-03 {
    top: 24.375rem;
    right: 25rem;
  }

  .security-privacy p.sm-text {
    width: 65%;
  }

  .security-privacy .services-info h3,
  .premium-features .features-card h3 {
    font-size: 1.25rem;
  }

  .navpills {
    left: 1.25rem;
  }

  .review-sec .owl-carousel.owl-drag .owl-item {
    padding-right: 0.9375rem;
  }

  .review-card {
    font-size: 0.875rem;
    padding: 0.9375rem;
  }

  .review-card .review-stars img {
    width: 1.25rem;
    height: 1.25rem;
  }

  .review-info h3 {
    font-size: 1.375rem;
  }

  .review-info p {
    font-size: 0.8125rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.625rem 0.625rem;
    font-size: 0.875rem;
  }

  .navbar-expand-lg .navbar-nav .nav-item .join-discord-link,
  .navbar-expand-lg .navbar-nav .nav-item .client-area-link {
    margin-right: 0;
    padding: 0.625rem 1rem;
  }

  .premium-features .features-card p {
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-right: 0;
  }

  .q-start h2 {
    font-size: 1.5625rem;
    margin-bottom: 0.375rem;
  }

  .checkout-rs h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .q-start p {
    font-size: 0.875rem;
  }

  .checkout-list .text-lab,
  .text-lab {
    padding: 0.25rem 0.3125rem;
    font-size: 0.6875rem;
  }

  .accounts .search {
    width: 100%;
  }

  .accounts .search input,
  .accounts .search input:focus {
    width: 100%;
  }

  .contact-features .features-card p {
    margin-bottom: 0.9375rem;
  }

  .contact-form-sec .container {
    max-width: 60rem;
  }

  .contact-form-sec .contact-form {
    padding: 1.25rem;
  }

  .contact-form-sec p {
    width: 70%;
  }

  .realtime-sp .leftimg img {
    height: 26.875rem;
  }

  .stopn-hesitating img {
    height: 17.5rem;
  }
}

@media (max-width: 62rem) {
  .stopn-hesitating .col-4 {
    width: 1.333%;
  }

  .stopn-hesitating .img-r {
    margin-left: auto;
    margin-right: -0.9375rem;
    display: block;
  }

  .stopn-hesitating img {
    height: 17.1875rem;
    margin-left: -0.9375rem;
  }

  .our-services .services-info p {
    margin-right: 50%;
  }

  .our-services .services-info h3 {
    margin-right: 80%;
  }
}

@media all and (min-width: 62rem) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
    -webkit-animation: dropdown-animation 0.2s;
    animation: dropdown-animation 0.2s;
    background: transparent;
    background-color: rgba(var(--bs-secondary-rgb), 0.85);
    backdrop-filter: blur(0.25rem);
  }

  .our-services .services-info p {
    margin-right: 45%;
  }
}

@media (max-width: 50rem) {
  .our-services .services-info h3 {
    margin-right: 0%;
  }

  .stroke {
    margin-top: 0rem;
    margin-bottom: 0.9375rem;
  }

  .legal-sec .legal-info {
    padding: 1.25rem;
  }

  .legal-sec .left-b-img {
    margin-left: -3.125rem;
    height: 14.375rem;
  }

  .legal-sec .legal-info h3 {
    font-size: 1.375rem;
  }

  .legal-sec ul li {
    width: 15rem;
  }

  .pack {
    margin-bottom: 1.875rem;
  }

  .header .h-content h1 {
    font-size: 3.125rem;
  }

  .header .h-content h3 {
    font-size: 1.125rem;
  }

  .header .btn-primary,
  .header .btn-outline-light,
  .Our-Safety .btn-primary {
    font-size: 0.875rem;
  }

  .our-services h2,
  .Our-Safety h2,
  .premium-features h2,
  .realtime-sp h2 {
    font-size: 2.8125rem;
  }

  .our-services .services-info img {
    top: -1.875rem;
    width: 15.625rem;
  }

  .our-services .info-transparent img {
    width: 30.5rem;
    right: -11.25rem;
    top: -1.25rem;
  }

  .our-services .services-info h3 {
    font-size: 1.375rem;
  }

  .our-services .services-info p {
    font-size: 0.8125rem;
    margin-right: 45%;
  }

  .our-services {
    padding: 2.5rem 0 8rem 0;
  }

  .Our-Safety .Safety-card p {
    font-size: 0.8125rem;
  }

  .Our-Safety .Safety-01 {
    top: 5rem;
    right: 1.875rem;
  }

  .Our-Safety .position-relative {
    padding-top: 14.0625rem;
    padding-bottom: 15.3125rem;
  }

  .Our-Safety .Safety-02 {
    top: 18.8125rem;
    right: 0.625rem;
  }

  .Our-Safety .Safety-03 {
    right: 22.625rem;
  }

  .Our-Safety p {
    font-size: 1rem;
  }

  .purchase-flow,
  .premium-features,
  .legal-sec {
    padding: 2.5rem 0 2.5rem 0;
  }

  .purchase-flow h2,
  .faq-section h2,
  .stopn-hesitating h2,
  .Eff-key-light h2,
  .legal-sec h2 {
    font-size: 2.8125rem;
  }

  .purchase-flow .nav-link span {
    font-size: 0.8125rem;
  }

  .purchase-flow .nav-link {
    width: 17.1875rem;
  }

  .purchase-flow .nav-link {
    font-size: 1.125rem;
  }

  .purchase-flow .tab-content .purchase-content img {
    width: 88%;
  }

  .navpills {
    left: 0;
  }

  .navpills .circle {
    height: 0.625rem;
    width: 0.625rem;
    margin: 0.5rem 0;
  }

  .security-privacy .services-info {
    padding: 0.9375rem;
    min-height: 11rem;
    margin-top: 0.9375rem;
  }

  .security-privacy .services-info h3,
  .premium-features .features-card h3 {
    font-size: 1.0625rem;
  }

  .security-privacy .services-info p {
    margin-right: 0;
  }

  .security-privacy .services-info img {
    width: 5.625rem;
    height: 5.625rem;
    top: auto;
  }

  .security-privacy,
  .faq-section {
    padding: 2.5rem 0 2.5rem 0;
  }

  .stopn-hesitating p {
    font-size: 1rem;
  }

  .stopn-hesitating .btn-primary {
    font-size: 0.875rem;
  }

  footer {
    padding: 2.1875rem 0 1.875rem;
  }

  footer p {
    margin-bottom: 0.9375rem;
  }

  footer .pay-icon {
    margin-bottom: 1.875rem;
  }

  footer h4 {
    font-size: 1rem;
  }

  footer li a {
    font-size: 0.8125rem;
  }

  footer .copyright {
    font-size: 0.8125rem;
  }

  .faq-section .accordion-button {
    font-size: 1.125rem;
  }

  .faq-section .accordion {
    font-size: 0.8125rem;
  }

  .review-card .review-stars img {
    width: 1.75rem;
    height: 1.75rem;
  }

  .navbar-toggler {
    background-color: var(--bs-primary);
    border-radius: 0.5rem;
    padding: 0;
    transition: all 0.4s ease-in-out;
    margin-right: 0.9375rem;
    box-shadow: none !important;
  }

  .navbar-nav .dropdown:hover > .dropdown-menu {
    animation: none;
  }

  .top-h-section {
    padding: 0.5rem 0;
  }

  .navbar-expand-lg .container {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .navbar-expand-lg .navbar-nav .nav-item {
    margin: 0;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }

  .navbar-collapse.show .navbar-nav .nav-item {
    opacity: 1;
    border-bottom: solid 0.0625rem rgba(194, 194, 194, 0.2);
  }

  #navbarNavDropdown .dropdown-menu {
    display: block !important;
  }

  #navbarNavDropdown .dropdown-menu {
    background-color: transparent !important;
    padding: 0;
  }

  #navbarNavDropdown .dropdown-menu .dropdown-item {
    background-color: transparent;
    color: #fff;
    border: none;
  }

  #navbarNavDropdown .dropdown-toggle::after {
    display: none;
  }

  #navbarNavDropdown .nav-item {
    border: none;
  }

  #navbarNavDropdown .dropdown .dropdown-menu .dropdown-item::before {
    display: none;
  }

  .navbar-nav .dropdown .dropdown-menu .dropdown-item {
    font-size: 1.125rem;
  }

  .dropdown-menu[data-bs-popper] {
    padding-top: 0rem;
    padding-bottom: 0.625rem;
    backdrop-filter: blur(0rem);
  }

  .navbar-brand,
  .navbar-brand:hover {
    font-size: 1.625rem;
  }

  .navbar-brand {
    margin-left: 0.9375rem;
  }

  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(0.25rem);
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    position: fixed;
    transition: all 0.4s ease-out, background 1s ease-out;
    top: 0;
    z-index: 2;
    margin: 0;
  }

  .navbar-expand-lg .navbar-nav {
    height: 100vh;
    padding: 3.75rem 0 0 0rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.625rem 0.9375rem;
    font-size: 1.25rem;
    color: var(--bs-primary);
    border-bottom: solid 0.0625rem #453913;
  }

  .navbar-expand-lg .navbar-nav .nav-item .join-discord-link,
  .navbar-expand-lg .navbar-nav .nav-item .client-area-link {
    margin-bottom: 0.9375rem;
  }

  .dropdown-menu[data-bs-popper] {
    padding-top: 0rem;
    padding-bottom: 0.625rem;
    backdrop-filter: blur(0rem);
  }

  .navbar-expand-lg .navbar-nav .nav-item .join-discord-link,
  .navbar-expand-lg .navbar-nav .nav-item .client-area-link {
    padding: 0.625rem 1.5rem;
    font-size: 1rem;
    text-align: center;
  }

  .navbar-expand-lg .navbar-nav .nav-item .client-area-link {
    color: var(--bs-secondary) !important;
  }

  .navbar-nav {
    display: block;
  }

  #navbarNavDropdown .nav-item:last-child,
  #navbarNavDropdown .nav-item:nth-last-child(2) {
    float: left;
    padding: 0.625rem;
  }

  .division-tabs .nav-pills .nav-link.active,
  .division-tabs .nav-pills .nav-link {
    font-size: 0.9375rem;
    padding: 0.75rem 1.25rem;
    border-radius: 1.5625rem;
    line-height: 1;
  }

  .pack-sec .nav-pills .nav-link.active,
  .pack-sec .nav-pills .nav-link {
    font-size: 1rem;
    padding: 0.75rem 2rem;
    border-radius: 1.875rem;
    line-height: 1.375rem;
  }

  .division-tabs .nav-pills .nav-link.active img,
  .division-tabs .nav-pills .nav-link img {
    margin-top: -0.1875rem;
  }

  .premium-features .features-card {
    padding: 0.9375rem;
  }

  .premium-features .features-card p {
    font-size: 0.8125rem;
  }

  .premium-features h2,
  .realtime-sp h2 {
    margin-bottom: 0.9375rem;
  }

  .mb-eff-key {
    margin-bottom: 1.25rem;
  }

  .Eff-key-light {
    padding: 2.5rem 0 0rem 0;
  }

  .Eff-key-light h3 {
    font-size: 1.375rem;
  }

  .eff-key-img img {
    height: 24.375rem;
    margin: auto;
  }

  .premium-pack .price-p-pack {
    padding-top: 1.875rem;
  }

  .md-w100 {
    width: 100%;
  }

  .premium-pack {
    margin-top: 0;
  }

  .contact-form-sec .contact-form {
    width: 70%;
    margin: auto;
  }

  .stopn-hesitating img {
    height: 11.875rem;
  }
}

@media (max-width: 47.9375rem) {
  .navbar-brand img {
    height: 2.1875rem;
  }

  .footer-logo img {
    width: auto;
    height: 2.1875rem;
  }

  .sc-up .navbar-brand img {
    height: 2rem;
  }

  .sc-up .top-h-section {
    height: 3.125rem;
  }

  .legal-sec .left-b-img {
    display: none;
  }

  .legal-sec ul {
    margin: 1.25rem 0;
    float: left;
  }

  .legal-sec ul li a {
    padding: 0.5625rem 0.625rem;
  }

  .legal-sec ul li a svg {
    width: 0.9375rem;
    height: 1rem;
  }

  .legal-sec ul li {
    width: 50%;
    float: left;
    padding-right: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .pack .packlist span {
    font-size: 0.9375rem;
  }

  .header .h-content h1 {
    font-size: 2.5rem;
  }

  .header .h-content h3 {
    font-size: 1rem;
  }

  .header .btn-primary,
  .header .btn-outline-light {
    margin: 0 0.3125rem;
  }

  .our-services h2,
  .Our-Safety h2,
  .premium-features h2,
  .realtime-sp h2 {
    font-size: 2.5rem;
  }

  .our-services p {
    font-size: 0.875rem;
  }

  .our-services .services-info img {
    top: -0.625rem;
    width: 13.125rem;
    right: 0;
  }

  .our-services .info-transparent img {
    margin-right: 0rem;
    width: 16.75rem;
    right: 0;
    top: 0;
  }

  .our-services .services-info {
    margin-top: 1.5625rem;
    margin-bottom: 0rem;
    overflow: hidden;
  }

  .our-services .services-info .btn-outline-light {
    font-size: 0.875rem;
    padding: 0.625rem 1.5625rem 0.5625rem 1.5625rem;
    border-radius: 1.25rem;
  }

  .our-services {
    padding: 2.5rem 0 2.5rem 0;
  }

  .Our-Safety p {
    font-size: 0.875rem;
  }

  .header .btn-primary,
  .header .btn-outline-light,
  .Our-Safety .btn-primary,
  .stopn-hesitating .btn-primary {
    font-size: 0.875rem;
    padding: 0.75rem 1.5625rem 0.625rem 1.5625rem;
    /* border-radius: 1.5rem; */
  }

  .Our-Safety {
    display: block;
  }

  .Our-Safety .position-relative {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .Our-Safety .Safety-01,
  .Our-Safety .Safety-02,
  .Our-Safety .Safety-03 {
    position: relative;
    width: 100%;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .Our-Safety .Safety-01 {
    margin-top: 4.375rem;
  }

  .Our-Safety .Safety-card img {
    width: 4.0625rem;
    height: 4.0625rem;
  }

  .Our-Safety .Safety-02 .Safety-card img {
    width: 5.3125rem;
    height: 5.3125rem;
  }

  .Our-Safety .Safety-03 .Safety-card img {
    width: 5rem;
    height: 5rem;
  }

  .purchase-flow h2,
  .faq-section h2,
  .stopn-hesitating h2,
  .Eff-key-light h2,
  .legal-sec h2 {
    font-size: 2.5rem;
  }

  .purchase-flow p,
  .Eff-key-light p {
    font-size: 0.875rem;
    margin-bottom: 0.9375rem;
  }

  .security-privacy p.sm-text {
    width: 100%;
  }

  .security-privacy .services-info {
    overflow: visible;
  }

  .security-privacy .services-info img {
    width: 5rem;
    height: 5rem;
    top: auto;
    right: -1.875rem;
  }

  .security-privacy .services-info {
    min-height: 7.625rem;
  }

  .security-privacy .btn-outline-light {
    font-size: 0.875rem;
    padding: 0.75rem 1.75rem;
  }

  .faq-section p {
    font-size: 0.875rem;
  }

  .faq-section .accordion-button {
    font-size: 0.9375rem;
  }

  .stopn-hesitating p {
    font-size: 0.875rem;
  }

  footer li {
    margin-bottom: 0.9375rem;
  }

  footer .col {
    flex: 0 0 auto;
    width: 50%;
    padding-bottom: 0.9375rem;
  }

  footer .copyright div:before,
  footer .copyright div:after {
    display: none;
  }

  footer .copyright {
    padding: 0.625rem 0.9375rem 0;
  }

  .purchase-flow .nav-link {
    width: 12.8125rem;
  }

  .purchase-flow .nav-link {
    font-size: 0.9375rem;
  }

  .purchase-flow .nav-link span {
    font-size: 0.6875rem;
  }

  .purchase-flow .tab-content .purchase-content img {
    width: 100%;
  }

  .navpills {
    display: none;
  }

  .review-info p {
    font-size: 0.8125rem;
    margin-bottom: 0;
  }

  .q-start .btn-continue {
    font-size: 0.875rem;
  }

  .premium-features .features-card {
    height: 100%;
    margin: 0;
  }

  .premium-features .col-6 {
    padding-bottom: 1.875rem;
  }

  .q-start .form-check-label {
    font-size: 0.875rem;
  }

  .radio-btn .form-check-label {
    padding-top: 0rem;
  }

  .q-start .form-check-input {
    margin-top: 0.0625rem;
  }

  .checkout-rs h2 {
    margin-bottom: 0.9375rem;
  }

  .shopcard .row-info img {
    display: inline-block;
    margin-bottom: 0;
  }

  .sort-select select {
    width: 8.3125rem;
    border-radius: var(--border-radius);
    font-size: 0.75rem;
  }

  .grid-view .btn-outline-light {
    padding: 0.625rem 0.9375rem;
    height: 2.25rem;
    font-size: 0.8125rem;
    border-radius: 1.5625rem;
  }

  .accounts .search input,
  .accounts .search input:focus {
    font-size: 0.8125rem;
    height: 2.25rem;
  }

  .accounts .search svg {
    position: absolute;
    height: 1.125rem;
    width: 1.125rem;
  }

  .shop-list-card .row-info {
    font-size: 0.8125rem;
    border: none;
    border-top: solid 0.0625rem #585d72;
    border-bottom: solid 0.0625rem #585d72;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-left: -0.3125rem;
    margin-right: -0.3125rem;
    margin-bottom: 0.5rem;
  }

  .shop-list-card .col-auto {
    width: 100%;
    max-width: 100%;
    flex: auto;
  }

  .shop-list-card .price {
    text-align: left;
  }

  .shop-list-card .btn-primary {
    width: 100%;
    padding: 0.625rem 0.625rem;
  }

  .shop-list-card .row-info img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.3125rem;
  }

  .premium-pack .pp-info {
    font-size: 0.9375rem;
  }

  .realtime-sp .leftimg img {
    height: 18.75rem;
    margin: auto;
    display: block;
  }

  .realtime-sp {
    padding-bottom: 2.5rem;
  }

  .contact-form-sec {
    padding: 2.5rem 0 2.5rem 0;
  }

  .contact-features .features-card {
    margin-bottom: 1.875rem !important;
    height: auto;
  }

  .stopn-hesitating .img-r {
    margin-left: auto;
    margin-right: -1.25rem;
  }

  .stopn-hesitating img {
    height: 10.3125rem;
    margin-left: -1.25rem;
  }
}

@media (max-width: 30rem) {
  .legal-sec .legal-info h3 {
    font-size: 1.25rem;
  }

  .pack .price-pack {
    font-size: 3rem;
  }

  .header .h-content h1 {
    font-size: 2.1875rem;
  }

  .h-content {
    min-width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .Our-Safety .Safety-01 {
    padding-left: 1.875rem;
  }

  .Our-Safety .Safety-02,
  .Our-Safety .Safety-03 {
    padding-right: 1.875rem;
  }

  .Our-Safety .Safety-03 {
    margin-top: 0;
  }

  .our-services h2,
  .Our-Safety h2,
  .premium-features h2,
  .realtime-sp h2 {
    font-size: 1.875rem;
  }

  .our-services .review-trustpilot {
    font-size: 0.875rem;
  }

  .our-services .review-trustpilot svg {
    height: 1.25rem;
  }

  .Our-Safety .Safety-card img {
    width: 3.4375rem;
    height: 3.4375rem;
    top: -1.875rem;
    left: -1.5625rem;
  }

  .Our-Safety .Safety-01 .Safety-card {
    padding-top: 1.875rem;
  }

  .Our-Safety .Safety-02 .Safety-card img {
    width: 4.6875rem;
    height: 4.6875rem;
    top: -1.25rem;
    right: -1.125rem;
  }

  .Our-Safety .Safety-02 .Safety-card,
  .Our-Safety .Safety-03 .Safety-card {
    padding-right: 2.6875rem;
  }

  .Our-Safety .Safety-03 .Safety-card img {
    width: 4.375rem;
    height: 4.375rem;
    right: -1.75rem;
    bottom: -1.25rem;
  }

  .purchase-flow h2,
  .faq-section h2,
  .stopn-hesitating h2,
  .Eff-key-light h2,
  .legal-sec h2 {
    font-size: 1.875rem;
  }

  .our-services .services-info h3 {
    font-size: 1.25rem;
  }

  .our-services .info-transparent img {
    right: -3.125rem;
  }

  .security-privacy .services-info img {
    right: 0;
    bottom: -1.6875rem;
  }

  footer .copyright div {
    padding: 0rem 0rem;
  }

  .d-flex-flow {
    display: block;
  }

  .purchase-flow .nav-link {
    width: 100%;
  }

  .purchase-flow .nav-link {
    font-size: 0.875rem;
    padding: 0.4375rem 0.625rem;
    margin: 0.3125rem 0;
  }

  .purchase-flow .tab-content .purchase-content {
    padding: 0.625rem;
  }

  .division-tabs .nav-pills .nav-link.active,
  .division-tabs .nav-pills .nav-link {
    font-size: 0.875rem;
    padding: 0.75rem 0.9375rem;
    border-radius: 1.25rem;
  }

  .q-start h2 {
    font-size: 1.375rem;
  }

  .checkout-rs h2 {
    font-size: 2rem;
    margin-bottom: 1.25rem;
  }

  /* .form-label {
    font-size: 0.875rem;
  } */

  .btn-check-icon .btn-outline-primary,
  .btn-check-icon .btn-check:active + .btn-outline-primary,
  .btn-check-icon .btn-check:checked + .btn-outline-primary,
  .btn-check-icon .btn-outline-primary.active,
  .btn-check-icon .btn-outline-primary.dropdown-toggle.show,
  .btn-check-icon .btn-outline-primary:active,
  .btn-check-icon .btn-outline-primary:hover {
    margin-bottom: 0.625rem;
    margin-right: 0.5rem;
  }

  .checkout-rs .buy-now {
    /* font-size: 0.875rem; */
  }

  .purchase-flow,
  .premium-features,
  .legal-sec {
    padding: 2.5rem 0 1.25rem 0;
  }

  .division-tabs,
  .accounts {
    padding: 2.5rem 0 2.5rem 0;
  }

  .division-tabs .nav-pills {
    margin-bottom: 1.875rem;
  }

  .shopcard {
    padding: 0.9375rem;
    font-size: 0.875rem;
  }

  .right-action .col {
    width: 100%;
    max-width: 100%;
    flex: auto;
    padding-bottom: 0.9375rem;
  }

  .sort-select select,
  .grid-view .btn-outline-light {
    width: 100%;
  }

  .grid-view {
    padding-left: 0.9375rem;
  }

  .btn-show,
  .btn-show:focus,
  .btn-show:hover {
    font-size: 0.875rem;
  }

  .Eff-key-light {
    padding-bottom: 0;
  }

  .eff-key-img img {
    height: 18.75rem;
    margin: auto;
  }

  .division-tabs .nav-pills .nav-link.active img,
  .division-tabs .nav-pills .nav-link img {
    width: 1.125rem;
    height: 1.125rem;
  }

  .pack-sec .nav-pills .nav-link.active,
  .pack-sec .nav-pills .nav-link {
    font-size: 0.875rem;
    padding: 0.5rem 1.5625rem;
    border-radius: 1.25rem;
  }

  .division-tabs .nav-pills .nav-link.active img,
  .division-tabs .nav-pills .nav-link img {
    margin-top: 0;
  }

  .premium-pack .price-p-pack {
    font-size: 3rem;
  }

  .premium-pack h3 svg {
    width: 1.3125rem;
    height: 1.3125rem;
    margin-top: -0.1875rem;
    margin-left: 0.5rem;
  }

  .realtime-sp .leftimg img {
    height: 15rem;
  }

  .contact-form-sec .contact-form,
  .contact-form-sec p {
    width: 100%;
  }

  .realtime-sp .btn-primary svg,
  .contact-features svg {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
  }

  .legal-sec {
    padding: 2.5rem 0 2.5rem 0;
  }

  .stopn-hesitating {
    padding-bottom: 6.25rem;
    padding-top: 1.875rem;
    overflow: hidden;
  }

  .stopn-hesitating .col-auto {
    z-index: 2;
  }

  .stopn-hesitating .img-r,
  .stopn-hesitating img {
    margin: 0;
    position: absolute;
    z-index: 1;
    left: -0.625rem;
    bottom: 0;
    height: 8.125rem;
  }

  .stopn-hesitating .img-r {
    left: auto;
    right: -0.625rem;
  }
}

@media (max-width: 26.5625rem) {
  .login .modal-body {
    padding: 1.25rem;
  }

  .login .modal-body h2 {
    font-size: 1.375rem;
  }

  .header .h-content h3 {
    font-size: 0.875rem;
    line-height: normal;
  }

  .login .modal-body p {
    margin-bottom: 0.5rem;
  }

  .security-privacy .services-info {
    min-height: 8.875rem;
  }

  .login .modal-body .btn-primary {
    margin-bottom: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .checkout-review {
    font-size: 0.875rem;
  }

  .checkout-rs .silver-icon,
  .checkout-rs .gold-icon {
    font-size: 0.875rem;
  }

  .checkout-list .list-title,
  .q-start p {
    font-size: 0.8125rem;
  }

  .checkout-rs .gold-icon span,
  .q-start .icon-rd-value {
    font-size: 4.0625rem;
    line-height: 3.75rem;
  }

  .premium-features .features-card {
    padding: 0.625rem;
  }

  .premium-features .features-card h3 {
    font-size: 0.9375rem;
  }

  .premium-features .features-card img {
    height: 1.75rem;
    width: 1.75rem;
  }

  .premium-features .features-card .pf-icon {
    height: 2.5rem;
    width: 2.5rem;
  }

  .checkout-rs .gold-icon span {
    padding-top: 0.4375rem;
    padding-bottom: 0.5rem;
  }

  .division-tabs .nav-pills .nav-link.active,
  .division-tabs .nav-pills .nav-link {
    padding: 0.75rem 0.75rem;
    border-radius: 1.25rem;
  }

  .q-start,
  .checkout-rs {
    padding: 0.9375rem;
  }

  .Eff-key-light h3 {
    font-size: 1.25rem;
  }

  .eff-key-img img {
    height: 15rem;
    margin: auto;
  }

  .Eff-key-light p,
  .realtime-sp p {
    font-size: 0.8125rem;
  }

  .pack-sec .nav-pills .nav-link.active,
  .pack-sec .nav-pills .nav-link {
    font-size: 0.875rem;
    padding: 0.5rem 1.5625rem;
    border-radius: 1.25rem;
  }

  .premium-pack .pp-img {
    height: 6.25rem;
    margin-top: 0.625rem;
    margin-right: 0.625rem;
  }

  .premium-pack h4.lab-premium {
    border-radius: 1.375rem;
  }

  .pp-lb {
    flex: auto;
    width: 100%;
    border: none;
    padding-left: 0.9375rem;
  }

  .premium-pack h3 {
    font-size: 1.5625rem;
    margin-top: 1.25rem;
  }

  .sm-w-100 {
    margin: auto;
    width: 100%;
  }

  .premium-pack .pp-img {
    height: 8.75rem;
  }

  .premium-pack h4.lab-premium {
    position: absolute;
    right: 0.625rem;
    top: 0rem;
  }

  .legal-sec .legal-info p {
    font-size: 0.8125rem;
    line-height: 1.375rem;
  }

  .legal-sec ul li {
    width: 100%;
    padding-right: 0rem;
  }
}

@media (max-width: 23.4375rem) {
  .b-info {
    margin-top: 0.9375rem;
    font-size: 0.8125rem;
  }

  .our-services .services-info p {
    margin-right: 40%;
  }

  .our-services .services-info img {
    right: -1.5625rem;
  }

  .our-services .info-transparent img {
    right: -3.75rem;
    top: 0.4375rem;
  }

  .security-privacy .services-info img {
    right: 0;
    bottom: -1.6875rem;
  }

  .security-privacy .services-info p {
    margin-right: 0;
  }

  .review-card .review-stars img {
    width: 1.625rem;
    height: 1.625rem;
  }

  .division-tabs .nav-pills .nav-link.active,
  .division-tabs .nav-pills .nav-link {
    padding: 0.75rem 0.5625rem;
    font-size: 0.8125rem;
  }

  .checkout-rs .buy-now {
    /* font-size: 0.8125rem; */
  }

  .total-amount {
    font-size: 1rem;
  }

  .checkout-list .text-lab,
  .text-lab {
    min-width: 3rem;
    padding: 0.25rem;
    margin-right: 0.625rem;
  }

  .pack-sec .nav-pills .nav-link.active,
  .pack-sec .nav-pills .nav-link {
    font-size: 0.875rem;
    padding: 0.5rem 1.25rem;
    border-radius: 1.25rem;
  }

  .realtime-sp .leftimg img {
    height: 13.75rem;
  }

  .realtime-sp .btn-primary svg,
  .contact-features svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.375rem;
  }
}

@media (max-width: 20rem) {
  .header .h-content h1 {
    font-size: 1.875rem;
    margin-bottom: 0.625rem;
  }

  .header .h-content h3 {
    margin-bottom: 0.9375rem;
  }

  .our-services .services-info {
    padding: 0.9375rem;
  }

  .our-services .review-trustpilot {
    font-size: 0.8125rem;
    padding: 0.375rem 0.625rem;
  }

  .our-services .services-info img {
    width: 11.25rem;
  }

  .our-services .info-transparent img {
    right: -5.625rem;
    top: 0.75rem;
    width: 15.875rem;
  }

  .security-privacy .services-info img {
    right: 0;
    bottom: -1.6875rem;
    width: 4.375rem;
    height: 4.375rem;
  }

  .review-card .review-stars img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .review-info h3 {
    font-size: 1.25rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 0.75rem;
    font-size: 1.125rem;
  }

  .navbar-nav .dropdown .dropdown-menu .dropdown-item {
    font-size: 1rem;
  }

  .navbar-expand-lg .navbar-nav .nav-item .join-discord-link,
  .navbar-expand-lg .navbar-nav .nav-item .client-area-link {
    padding: 0.625rem 1.125rem;
    font-size: 0.875rem;
  }

  .premium-features .features-card p {
    font-size: 0.75rem;
  }

  .premium-features .features-card h3 {
    font-size: 0.875rem;
  }

  .division-tabs .nav-pills .nav-link.active,
  .division-tabs .nav-pills .nav-link {
    padding: 0.75rem 0.375rem;
    font-size: 0.75rem;
  }

  .q-start h2,
  .checkout-rs h2 {
    font-size: 1.25rem;
  }

  .q-start p {
    font-size: 0.75rem;
  }

  .checkout-list .list-title {
    font-size: 0.6875rem;
  }

  .q-start,
  .checkout-rs {
    padding: 0.625rem;
  }

  .pack-sec .nav-pills .nav-link.active,
  .pack-sec .nav-pills .nav-link {
    font-size: 0.875rem;
    padding: 0.375rem 1.125rem;
    border-radius: 1rem;
  }

  .premium-pack .pp-img {
    height: 7.5rem;
  }

  .contact-form-sec .contact-form {
    padding: 0.9375rem;
  }
}

@keyframes dropdown-animation {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* menu - update */
.leftnav .btn-left-nav {
  text-align: left;
  font-family: var(--bs-font-menu);
  font-size: 0.9375rem;
  padding: 1rem 1rem;
  display: flex;
  border-radius: var(--border-radius);
  text-decoration: none;
  width: 100%;
  line-height: 1.125rem;
  color: var(--bs-white);
  font-family: var(--bs-font-menu);
  margin: 0;
  border: var(--bs-primary);
}

.leftnav .btn-left-nav:hover,
.leftnav .btn-left-nav:focus {
  background-color: var(--bs-primary);
  color: var(--bs-secondary);
  border: var(--bs-primary);
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border: 0;
  position: relative;
}

.leftnav ul li .btn-toggle svg {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.9375rem;
  margin-top: -0.125rem;
}

.btn-toggle:focus svg {
  fill: var(--bs-secondary) !important;
}

.btn-toggle:focus {
  color: var(--bs-secondary) !important;
  background-color: var(--bs-primary);
}

.btn-toggle:hover svg {
  fill: var(--bs-secondary);
}

.btn-toggle:hover {
  color: var(--bs-secondary);
  background-color: var(--bs-primary);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  position: absolute;
  right: 0.625rem;
  margin: auto;
  filter: invert(1);
}

.btn-toggle[aria-expanded='true'] {
  color: #fff;
}

.btn-toggle[aria-expanded='true']::before {
  transform: rotate(90deg);
}

.scrollarea {
  overflow-y: auto;
}

.btn-toggle-nav {
  margin-top: 0.625rem !important;
}

.btn-toggle-nav a {
  padding: 0.5625rem 1rem !important;
}

.btn-toggle-nav li {
  margin-top: 0 !important;
  border-left: solid 0.0625rem #696048;
  margin-left: 1.5625rem;
  padding-left: 0.625rem;
}

.leftnav ul li ul li a:hover,
.leftnav ul li ul li a.active {
  background: transparent !important;
  color: var(--bs-primary);
  /* -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text; */
}

.leftnav ul li ul li a {
  color: #93959f;
  font-family: var(--bs-font-regular);
}

.leftnav .btn-left-nav:hover svg {
  fill: var(--bs-secondary) !important;
}

.leftnav .btn-toggle:hover::before {
  filter: none !important;
}

@media (max-width: 85.375rem) {
  .leftnav .btn-left-nav {
    padding: 0.75rem 0.625rem;
    line-height: 0.9375rem;
  }

  .btn-toggle-nav li {
    margin-left: 1.125rem;
    padding-left: 0.625rem;
  }
}

@media (max-width: 64rem) {
  .leftnav .btn-left-nav {
    font-size: 0.8125rem;
  }
}

@media (max-width: 50rem) {
  .leftnav .btn-left-nav {
    font-size: 0.9375rem;
  }
}

.pl-pr-15 {
  padding-left: 15px;
  padding-right: 15px;
  /* padding-top: 0px; */
}

/* menu - update */
.server-info-modal .modal-content {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  min-height: 35rem;
  background-color: #171630;
  border: solid 0.0625rem var(--bs-primary);
  overflow: hidden;
}

.server-info-modal .btn-close {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  filter: invert();
  opacity: 8;
}

.server-info-modal .modal-body {
  padding: 0rem;
  text-align: left;
}

.topHead-modal {
  background-image: url(../images/modal-sever-bg-top.jpg);
  background-position: top right;
  padding: 1.875rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.topHead-modal h1 {
  font-size: 1.75rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.topHead-modal p {
  font-size: 1.125rem;
  margin-bottom: 0;
}

.topHead-modal p svg {
  height: 1rem;
  height: 1rem;
  margin-top: -0.125rem;
  margin-right: 0.3125rem;
}

.card-modal {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  padding: 0.9375rem;
  width: 100%;
  margin-top: 0.9375rem;
}

.card-modal p {
  font-size: 0.875rem;
}

.card-modal h2 {
  font-size: 1.5rem;
}

.right-frame-val {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
  background-image: url(../images/val-fram.webp);
  background-size: 100%;
  display: block;
  text-align: center;
  vertical-align: middle;
  font-size: 1.25rem;
  line-height: 6.0625rem;
}

.val-kb-icons {
  font-size: 1.125rem;
  margin-top: 0.625rem;
}

.val-kb-icons img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  margin-top: -0.125rem;
}

.rank-c-info {
  text-align: center;
  color: #94959f;
  font-size: 0.875rem;
}

.rank-c-info h3 {
  font-size: 1.25rem;
  font-family: var(--bs-body-font-family);
  font-weight: 400;
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
  color: #fff;
}

.rank-c-info span {
  display: block;
  font-weight: 600;
  width: fit-content;
  margin: auto;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rank-c-info img {
  width: auto;
  height: 4.375rem;
}

.border-l {
  border-left: solid 0.0625rem #3a3e4a;
}

.search-bar {
  width: 15.625rem;
  margin-right: 0;
  margin-left: auto;
  position: relative;
}

.search-bar svg {
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  top: 0.5625rem;
  left: 0.5625rem;
  opacity: 0.5;
}

.search-bar input,
.search-bar input:focus {
  width: 15.625rem;
  font-size: 0.8125rem;
  padding: 0.375rem 0.5625rem 0.375rem 2.1875rem;
}

.collection {
  padding: 0.875rem;
  background-color: #171630;
  height: 18.5rem;
  overflow-y: auto;
  border-radius: 0.3125rem;
  margin-top: 0.625rem;
  border: 2px solid rgba(255, 251, 217, 0.25);
}

.collection img {
  width: auto;
  height: 5.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 0.375rem;
}

.Gallery-img b {
  font-size: 1rem;
  margin-bottom: 0.9375rem;
  display: block;
  font-weight: 600;
}

.Gallery-img img {
  width: 100%;
  height: auto;
  border-radius: 0.375rem;
  aspect-ratio: 16/9;
  border: 1px solid rgba(255, 251, 217, 0.25);
}

.btn-share,
.btn-share:hover,
.btn-share:focus {
  padding: 0.3125rem;
  margin: 0;
  border: solid 0.0625rem var(--bs-primary);
  border-radius: 50%;
  margin-top: -0.125rem;
  height: 2.125rem;
  width: 2.125rem;
  float: right;
  line-height: 1;
  color: var(--bs-primary);
}

.btn-share svg {
  width: 1rem;
  height: 1rem;
}

.btn-share:hover,
.btn-share:focus {
  background-color: var(--bs-primary);
  color: var(--bs-secondary);
}

.checkout-div {
}

.checkout-div h2 {
  font-size: 1.75rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: table;
  margin: auto;
}

.checkout-div .c-list {
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  display: block;
  letter-spacing: normal;
}

.checkout-div .c-list svg {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.75rem;
  color: var(--bs-primary);
}

.checkout-div .total-amount {
  font-size: 1.25rem;
}

.checkout-div .text-lab,
.text-lab {
  padding: 0.25rem 0.4375rem;
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1;
  color: var(--bs-primary);
  border: solid 0.0625rem var(--bs-primary);
  line-height: 1;
  border-radius: 0.5rem;
  letter-spacing: normal;
  text-align: center;
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  min-width: 3.25rem;
  margin-right: 0.9375rem;
}

.checkout-div .total-amount .amount {
  font-size: 1.75rem;
  line-height: 1;
}

.checkout-div .total-amount .amount span {
  padding-top: 0.3125rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mb-15 {
  margin-bottom: 0.9375rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

@media (max-width: 1024px) {
  .server-info-modal .modal-xl {
    max-width: 95%;
  }
}

@media (max-width: 800px) {
  .collection img {
    height: 5rem;
  }

  .topHead-modal {
    background-position: top center;
  }
}

@media (max-width: 480px) {
  .card-modal h2 {
    font-size: 1.375rem;
  }

  .val-kb-icons {
    font-size: 1rem;
  }

  .rank-c-info img {
    height: 3.4375rem;
  }

  .rank-c-info {
    font-size: 0.8125rem;
    letter-spacing: normal;
  }

  .rank-c-info h3 {
    font-size: 1rem;
  }

  .search-bar {
    width: 13.125rem;
  }

  .search-bar input,
  .search-bar input:focus {
    width: 13.125rem;
    font-size: 0.75rem;
  }

  .search-bar svg {
    width: 1rem;
    height: 1rem;
  }

  .checkout-div h2 {
    font-size: 1.75rem;
  }

  .checkout-div .total-amount .amount {
    font-size: 1.625rem;
  }

  .topHead-modal h1 {
    font-size: 1.5rem;
  }

  .topHead-modal p {
    font-size: 1rem;
  }

  .topHead-modal {
    padding: 1rem;
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 425px) {
  .val-kb-icons {
    font-size: 0.875rem;
  }

  .val-kb-icons img {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.375rem;
  }

  .rank-c-info img {
    height: 2.8125rem;
  }

  .rank-c-info h3 {
    font-size: 0.9375rem;
  }

  .collection img {
    height: 5.9375rem;
  }

  .checkout-div .c-list {
    font-size: 0.8125rem;
  }

  .checkout-div .text-lab,
  .text-lab {
    margin-right: 0;
  }

  .right-frame-val {
    width: 5rem;
    height: 5rem;
    font-size: 1rem;
    line-height: 5rem;
  }
}

@media (max-width: 414px) {
  .collection img {
    height: 5.75rem;
  }
}

@media (max-width: 375px) {
  .card-modal h2 {
    font-size: 1.25rem;
  }

  .val-kb-icons {
    font-size: 0.75rem;
  }

  .rank-c-info {
    font-size: 0.75rem;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }

  .search-bar,
  .search-bar input,
  .search-bar input:focus {
    width: 100%;
  }

  .sm-w-s {
    flex: auto;
  }

  .collection img {
    height: 5rem;
  }

  .checkout-div .total-amount {
    font-size: 0.875rem;
  }
}

@media (max-width: 320px) {
  .collection img {
    height: 6.25rem;
  }

  .trustpilot-energy-reviews-footer-logo {
    margin-left: 0px;
    width: 120px;
  }

  .val-kb-icons img {
    display: block;
  }
}

.order-thank .modal-content {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background-image: url(../images/modal-order-bg.jpg);
  background-position: top right;
  background-color: #171630;
  border: solid 0.0625rem var(--bs-primary);
}

.order-thank .btn-close {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  filter: invert();
  opacity: 8;
}

.order-thank .modal-body {
  padding: 1.875rem;
  text-align: left;
}

.order-thank-form {
}

.order-thank-form h1 {
  font-size: 2rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.order-thank-form h3 {
  font-size: 1.25rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  margin: auto auto 1.5625rem auto;
  text-align: center;
  width: fit-content;
}

.order-thank-form .p-gra {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}

.order-thank-form h5 {
  font-size: 1rem;
  margin-bottom: 0.9375rem;
}

.order-thank-form .ch-btn-ps {
  margin-top: 1.6875rem;
}

.order-thank-form .order-thank-detail {
  border-radius: var(--border-radius);
  color: var(--bs-white);
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    130deg,
    rgba(244, 222, 147, 0.15) 0%,
    rgba(26, 156, 251, 0.1) 100%
  );
  background: linear-gradient(130deg, rgba(244, 222, 147, 0.15) 0%, rgba(26, 156, 251, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(244,222,147,0.15)", endColorstr="#rgba(26,156,251,0.1)", GradientType=1);
  padding: 1.25rem;
  backdrop-filter: blur(1rem);
}

.order-thank-detail .checkout-rs {
  padding: 0;
  background-color: transparent !important;
  border: none;
  height: auto;
  display: block;
}

.order-thank-detail .order-thank-row {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: var(--border-radius);
  padding: 0.5rem 0.9375rem;
  margin-top: 0.9375rem;
  font-size: 1rem;
}

.priority-style {
  border: solid 0.0625rem var(--bs-primary) !important;
}

.order-thank-detail .order-thank-row .roles-icon {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.3125rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  width: max-content;
  width: 1.875rem;
  height: 1.875rem;
  margin: auto;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-top: 0.3125rem;
}

.order-thank-detail .order-thank-row .cham-img {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  border: solid 0.125rem var(--bs-secondary);
  margin-left: -0.5rem;
  background-color: #222a44;
  line-height: 1.75rem;
  float: left;
  text-align: center;
  font-size: 0.8125rem;
}

.text-color-g {
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}

.pb-25 {
  padding-bottom: 1.5625rem;
}

@media (max-width: 1024px) {
  .order-thank .modal-xl {
    max-width: 95%;
  }
}

@media (max-width: 800px) {
  .row-reverse-800 {
    flex-direction: column-reverse;
  }

  .order-thank-detail {
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 767px) {
  .order-thank .modal-body {
    padding: 1.875rem 0.9375rem 0.9375rem 0.9375rem;
  }

  .order-thank .btn-close {
    top: 0.3125rem;
    right: 0.375rem;
  }
}

@media (max-width: 425px) {
  .order-thank-form h3 {
    font-size: 1.125rem;
  }

  .order-thank-form .order-thank-detail {
    padding: 0.9375rem 0.625rem 0.625rem 0.625rem;
  }

  .order-thank-detail .order-thank-row {
    padding: 0.375rem 0.9375rem;
    margin-top: 0.625rem;
    font-size: 0.875rem;
  }

  .order-thank-detail .order-thank-row .roles-icon {
    margin-left: 0.3125rem;
    margin-top: 0;
    margin-right: 0;
  }

  .order-thank-form h1 {
    font-size: 1.5625rem;
  }

  .order-thank-form .p-gra {
    font-size: 0.875rem;
  }
}

@media (max-width: 375px) {
  .order-thank-form h3 {
    font-size: 1rem;
  }

  .order-thank-form .ch-btn-ps {
    font-size: 0.8125rem;
    padding: 0.75rem;
  }

  .order-thank .modal-body {
    padding: 1.875rem 0.5rem 0.9375rem 0.5rem;
  }

  .order-thank-detail .order-thank-row .cham-img {
    width: 1.625rem;
    height: 1.625rem;
    margin-left: -0.3125rem;
    line-height: 1.375rem;
  }
}

@media (max-width: 375px) {
  .order-thank-form h3 {
    font-size: 0.875rem;
  }

  .trustpilot-energy-reviews-footer-logo {
    margin-left: 0px;
    width: 120px;
  }
}

.trustpilot-energy-reviews-footer-logo {
  margin-left: -15px;
}

.redius-input-server .btn-outline-primary {
  border-radius: 2.5rem !important;
  padding: 0.75rem 1.125rem !important;
}

.no-accs {
  background-color: #fff4bc;
  border-radius: 10px;
  border: 2px solid #ffd956;
  color: #171630;
  display: inline-block;
  padding: 0.5rem 1rem;
}

.skin-icon {
  /* // set aspect-ratio: to 90/164; */
  aspect-ratio: 90/164;
  object-fit: cover;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050; /* This should be higher than the z-index of the first modal but lower than the second one */
}

@media (max-width: 768px) {
  .account-shop-header {
    min-height: 10rem !important;
    max-height: 12.5rem !important;
  }

  .boosting-header {
    min-height: 10rem !important;
    max-height: 12.5rem !important;
  }

  .homepage-header {
    min-height: 15rem !important;
    max-height: 25rem !important;
  }

  .place-order-btn {
    margin-top: -0.9rem !important;
    margin-bottom: -2rem !important;
  }
}

.font-weight-600 {
  font-weight: 600;
}

.rbt-input-main {
  caret-color: #fff;
  color: #fff;
  margin-left: 0.25rem;
}

.rbt-menu {
  background: linear-gradient(90deg, #38343f 0%, #182445 100%);
  border: 1px solid rgba(193, 193, 193, 0.5);
}

.dropdown-item {
  color: #fff;
  font-size: 14px;
}

.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
  background-color: #2f2e45;
}

.rbt-highlight-text {
  background-color: inherit;
  color: #ffea98;
  padding: 0;
}

.dropdown-item:hover,
.dropdown-item:active {
  text-decoration: none;
  background-color: #182445;
  color: white;
}

.rbt > .form-control {
  padding: 0.25rem 0.5rem;
  border-radius: 0px;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.rbt {
  flex-grow: 1;
  max-width: 25rem;
}

input.rbt-input-hint {
  color: #c1c1c1 !important;
}

.rbt-close {
  padding: 0px;
  height: 0.7rem;
  width: 0.7rem;
  display: flex;
  align-self: center;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFD956%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e');
  background-position: center;
  background-size: 1em auto;
  background-repeat: no-repeat;
}

.rbt-close-content {
  display: none;
}

.rbt-token {
  display: inline-flex !important;
  align-items: center;
  border-radius: 0.25rem;
  margin: 0.15rem;
  gap: 0.5rem;
  padding: 0.15rem 0.5rem;
  background-color: #171630;
  color: #fff;
  font-size: 14px;
}

.input-group-text {
  background-color: #c1c1c1;
  border: none;
}

.dropdown-header {
  color: var(--bs-primary);
  padding: 0.5rem 0.5rem;
}

.rbt-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: 1.9rem;
  align-items: center;
}

.rbt.form-control {
  padding: 0;
  border: none;
}

.rbt-input.focus {
  border-color: #c1c1c1;
}

.rbt-input-multi:not(.focus):hover {
  border-color: rgba(193, 193, 193, 0.45);
}
