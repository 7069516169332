@import url('./assets/css/bootstrap.min.css');
/* @import url('./assets/dist/assets/owl.carousel.min.css'); */
/* @import url('./assets/dist/assets/owl.theme.default.css'); */
@import url('./assets/css/config.css');
@import url('./assets/css/order-new.css');
@import url('./assets/css/style.css');
@import url('./assets/css/admin-1.css');
@import url('./assets/css/common.css');
@import url('./assets/css/admin.css');

a {
  text-decoration: none;
}

.StripeElement {
  background-color: #fff;
  padding: 12px 12px;
  border-radius: 10px;
}

.pl-8 {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.my-hover:hover {
  background-color: white;
  color: #171630;
}

.my-golden-text {
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

strike {
  color: lightgray;
  text-decoration: none;
  position: relative;
}
strike::before {
  top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
  background: rgb(255, 217, 86);
  opacity: 1;
  content: '';
  width: 100%;
  position: absolute;
  height: 0.15em;
  border-radius: 0.1em;

  white-space: nowrap;
  display: block;
  transform: rotate(-10deg);
}
/* strike.straight::before {
  transform: rotate(0deg);
  left: -1%;
  width: 102%;
} */

.my-warning-bg-hover:hover {
  background-color: #dc3545;
  border: solid 2px #dc3545 !important;
}

.my-mh-link {
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.my-mh-link:hover {
  text-decoration: none;
}

.my-mh-link:after {
  content: '';
  position: absolute;
  bottom: -0.025rem;
  left: 0;
  width: 0%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  height: 0.0625rem;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  background-color: var(--bs-primary);
}

.my-mh-link:hover:after {
  width: 100%;
}

.tooltip-inner {
  border: 2px solid black;
  background-color: rgb(35, 34, 68);
  font-family: 'Poppins', 'sans-serif';
  font-weight: 500;
}

.my-test-hover:hover {
  cursor: pointer;
}

.my-test-hover:hover > svg {
  color: #ffd956;
}

.my-click:focus {
  background: var(--bs-primary);
  color: var(--bs-secondary);
}

.accordion-body {
  padding-top: 0rem;
}

.btn-hover:hover {
  background: -webkit-linear-gradient(45deg, #ffd956, #fffbd9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social-login,
.social-login-google > div > div {
  border: 2px solid rgb(23, 22, 48) !important;
  border-radius: 50px;
}

.social-login:hover,
.social-login-google > div > div:hover {
  border: 2px solid #dadce0 !important;
  border-radius: 50px;
}

.CookieConsent {
  max-width: 20rem;
  margin: 1.5rem;
  border-radius: var(--border-radius);
  border: solid 0.0625rem white;
  background-color: var(--bs-secondary);
}

.CookieConsent:hover {
  border: solid 0.0625rem #ffd956;
}

#rcc-confirm-button {
  border: solid 0.0625rem #00b67a !important;
}

#rcc-confirm-button:hover {
  border: solid 0.0625rem white !important;
}

#rcc-decline-button:hover {
  color: #ffd956 !important;
}

#loginModel {
  font-size: 1rem;
}
