.pending-order-page {
  height: auto;
}

.ordertable2 .lab-td {
  padding: 0.3125rem 0.4375rem;
  line-height: 1;
  color: #9998a3;
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  display: inline-block;
  margin-right: 0.3125rem;
}

.pending-order-page h3 {
  font-family: var(--bs-body-font-family);
  font-weight: normal;
  font-size: 1.125rem;
  margin-bottom: 0 !important;
  padding-top: 0.3125rem;
}

.pending-order-page .title-type {
  float: left;
  display: inline-block;
  font-size: 0.875rem;
  padding-top: 0.25rem;
  padding-right: 0.625rem;
  color: #a2a2a4;
}

.pending-order-page .btn-group > .btn:nth-child(n + 3),
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 1.375rem;
  padding: 0.4375rem 1.25rem;
  font-size: 0.875rem;
  font-family: var(--bs-body-font-family) !important;
}

.pending-order-page .border-start {
  padding-left: 0.9375rem;
  border-left: 1px solid rgba(121, 122, 131, 0.35) !important;
  padding-bottom: 0.375rem;
}

.pending-order-page .ordertable2 .gold-icon img {
  background: var(--bs-primary);
}

.pending-order-page .ordertable2 .sm-t {
  font-size: 1rem;
}

.pending-order-page .ordertable2 .arr-right {
  margin-top: 0.875rem;
}

.pending-order-page .ordertable2 .accordion-button {
  width: fit-content;
  padding: 0.625rem;
  background-color: transparent;
  float: right;
  box-shadow: none;
  border: none;
  margin-top: -0.4375rem;
}

.pending-order-page .ordertable2 .accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFD956'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.ordertable2 .sm-t {
  font-size: 0.8125rem;
  display: block;
  color: var(--bs-primary);
}

.pending-order-page .row-title {
  background: -webkit-linear-gradient(45deg, #ffd956, #fff 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  /* margin-top: 0.625rem; */
  display: inline-block;
  margin: auto;
}

.pending-order-page .ordertable2 .accordion-collapse {
  border-top: 1px solid rgba(121, 122, 131, 0.35);
  margin-top: 0.9375rem;
  padding-top: 0.9375rem;
}

.pending-order-page .ordertable2 .set-w-icons {
  width: 13rem;
}

.pending-order-page .ordertable2 .collaps-order-d {
  font-weight: bold;
  padding-bottom: 0.9375rem;
}

.pending-order-page .ordertable2 .collaps-order-d span {
  font-weight: normal;
  display: block;
}

.pending-order-page .ordertable2 .collaps-order-d .ord-lab-d {
  padding: 0.6875rem 0.875rem;
  line-height: 1;
  color: #9998a3;
  background-color: #2c2b42;
  border: solid 0.0625rem #3c3c4e;
  border-radius: 0.25rem;
  width: fit-content;
  margin-top: 0.75rem;
}

.pending-order-page .ordertable2 .collaps-order-d .y-lab {
  background: -webkit-linear-gradient(45deg, #ffd956, #fff 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-top: 0.625rem;
  border-color: #82818f;
}

/* .collaps-order-d .col-auto {
  padding-left: 3.125rem;
} */

.pending-order-page .ordertable2 .collaps-order-d .icon-s {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.3125rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  width: max-content;
  width: 2.625rem;
  height: 2.625rem;
  margin: auto;
  display: inline-block;
  margin-right: 0.625rem;
  margin-top: 0.3125rem;
}

.Champions-img h4 {
  font-weight: bold;
  font-size: 0.9375rem;
  margin-bottom: 0.9375rem;
  margin-top: 0.9375rem;
}

.Champions-img p {
  font-size: 0.8125rem;
  margin-bottom: 0;
  opacity: 0.5;
}

.Champions-img .ch-img {
  padding-left: 0.625rem;
}

.Champions-img img {
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  border: solid 0.125rem var(--bs-secondary);
  margin-left: -0.625rem;
  background-color: #222a44;
  line-height: 2.0625rem;
  float: left;
  text-align: center;
  font-size: 0.8125rem;
  margin-bottom: 0.625rem;
}

.collaps-order-bottom {
  background-color: #23223b;
  margin: 1.25rem 0 0 0;
  border-radius: var(--border-radius);
  padding: 0.9375rem 0.3125rem;
}

.collaps-order-bottom .time {
  font-size: 0.8125rem;
  color: var(--bs-primary);
  display: block;
}

.collaps-order-bottom .time svg {
  margin-top: -0.125rem;
  margin-right: 0.3125rem;
}

.collaps-order-bottom .earnings-val {
  font-size: 1rem;
  line-height: 1;
  margin-top: 0.3125rem;
}

.collaps-order-bottom .earnings-val span {
  font-family: var(--bs-font-sans-serif);
  color: var(--bs-primary);
  font-size: 1.75rem;
  margin-left: 0.3125rem;
}

.collaps-order-bottom .order-id {
  font-size: 0.875rem;
  background-color: #2e2d44;
  border-radius: var(--border-radius);
  padding: 0.5rem 0.9375rem;
  display: inline-block;
}

.collaps-order-bottom .btn-outline {
  margin: auto 0 auto auto;
}

.chat-box .chat-head h3 {
  font-size: 1.125rem;
  font-weight: bold;
}

.cols {
  flex: 0 0 auto;
  margin-top: auto;
  margin-bottom: auto;
}

.col_01 {
  width: 20%;
}

.col_02 {
  width: 20%;
}

.col_03 {
  width: 12%;
}

.col_04 {
  width: 12%;
}

.col_05 {
  width: 20%;
}

.col_06 {
  width: 16%;
}

.col_03 svg {
  width: 1.375rem;
  height: 1.375rem;
  fill: #fff;
  margin-right: 0.3125rem;
}

.col_03 b {
  color: var(--bs-primary);
  display: block;
  font-size: 1rem;
  padding-left: 1.875rem;
  line-height: 1;
}

.earning {
  font-weight: bold;
  font-size: 1.125rem;
}

.rc-img {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
  border: solid 0.125rem var(--bs-secondary);
  margin-left: -0.75rem;
  background-color: #222a44;
  line-height: 2.0625rem;
  float: left;
  text-align: center;
  font-size: 0.8125rem;
}

.order-list-row {
  background-color: #171630;
  border-radius: var(--border-radius);
  padding: 0.75rem;
  margin-top: 0.9375rem;
  margin-bottom: 0;
}

.priority-title {
  display: none;
}

.priority-set {
  padding-top: 0;
  border: solid 0.0625rem var(--bs-primary);
}

.priority-set .priority-title {
  display: block;
  font-size: 0.8125rem;
  text-align: center;
  background: rgb(255, 217, 86);
  background: -moz-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 217, 86, 1) 0%, rgba(255, 251, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd956", endColorstr="#fffbd9", GradientType=1);
  color: var(--bs-secondary);
  margin-bottom: 0.9375rem;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  padding: 0.3125rem 0;
  line-height: 1;
}

.ordertable2 .gold-icon img,
.ordertable2 .silver-icon img {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.3125rem;
  border-radius: var(--border-radius);
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  width: max-content;
  width: 2.8125rem;
  height: 2.8125rem;
  margin: auto;
  display: block;
  margin-bottom: 0.3125rem;
}

.ordertable2 .silver-icon,
.ordertable2 .gold-icon {
  font-size: 0.75rem;
}

.order-t-po {
  float: right !important;
}

.customer-note h4 {
  font-size: 1.125rem;
  margin-top: 1.5625rem;
}

.customer-note p {
  font-size: 0.8125rem;
  opacity: 0.6;
}

.earn-ord-d {
  border: solid 0.0625rem var(--bs-primary);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: #fff;
  font-size: 1.125rem;
}

.earn-ord-d span {
  background: -webkit-linear-gradient(45deg, #ffd956, #fff 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-top: 0.625rem;
  display: block;
  letter-spacing: normal;
  line-height: 1;
  font-size: 2rem;
  width: fit-content;
}

.right-side-detail {
  padding-bottom: 1.875rem;
}

.right-side-detail h1 {
  font-size: 2rem;
  background: -webkit-linear-gradient(45deg, #ffd956, #fff 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.right-side-detail .top-title-d {
  margin-top: 0.9375rem;
  font-size: 1.125rem;
  font-weight: normal;
}

.right-side-detail .lab-title {
  font-size: 1rem;
}

.right-side-detail .lab-title .ord-lab-info {
  margin-top: 0.625rem;
  display: block;
  padding: 0.6875rem 0.875rem;
  line-height: 1;
  background-color: #363e56;
  border: solid 0.0625rem #878b9a;
  border-radius: 0.25rem;
  width: fit-content;
  font-weight: 600;
}

.right-side-detail .lab-title .ord-lab-info svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: #fff;
  margin-right: 0.3125rem;
  margin-top: -0.125rem;
}

.right-side-detail .lab-title .type-pr {
  border: solid 0.0625rem var(--bs-primary);
  background-color: #42444a;
}

.right-side-detail .lab-title .type-pr span {
  background: -webkit-linear-gradient(45deg, #ffd956, #fff 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.right-side-detail .lab-title .solo-pr {
  border: solid 0.0625rem #43495c;
  background-color: #323b54;
}

.right-side-detail .lab-title .solo-pr span {
  color: #7e7e7e;
}

.right-side-detail .lab-title .game-mode span {
  background: -webkit-linear-gradient(45deg, #ffd956, #fff 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.right-side-detail .lab-title .icon-s {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.3125rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem rgba(255, 255, 255, 0.4);
  width: max-content;
  width: 3.25rem;
  height: 3.25rem;
  margin: auto;
  display: inline-block;
  margin-right: 0.625rem;
  margin-top: 0.625rem;
}

.opacity-50 {
  opacity: 0.5;
}

.champs-img {
  padding-top: 0.9375rem;
  padding-left: 0.75rem;
}

.champs-img .rc-img {
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  border: solid 0.125rem var(--bs-secondary);
  margin-left: -0.75rem;
  background-color: #222a44;
  line-height: 2.625rem;
  float: left;
  text-align: center;
  font-size: 1rem;
}

.ac-links {
  color: var(--bs-primary);
}

.ac-links a {
  margin-left: 0.625rem;
}

.ac-links .mr-2 {
  margin-right: 0.625rem;
}

.ac-links svg {
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-7 {
  margin-top: 0.4375rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

@media (max-width: 1366px) {
  .right-side-detail .lab-title {
    font-size: 0.875rem;
  }

  .right-side-detail .lab-title .icon-s {
    width: 3rem;
    height: 3rem;
  }

  .right-side-detail h1,
  .earn-ord-d span {
    font-size: 1.75rem;
  }
}

.right-side-detail .top-title-d,
.earn-ord-d {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .champs-img .rc-img {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.25rem;
    font-size: 0.875rem;
  }

  .pending-order-page .btn-group > .btn:nth-child(n + 3),
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 1.375rem;
    /* padding: 0.3125rem 0.625rem;
    font-size: 0.8125rem; */
    margin-right: 0.3125rem;
  }

  .pending-order-page .border-start {
    padding-left: 0.5rem;
    padding-bottom: 0.1875rem;
    margin-left: 0.25rem;
  }

  .pending-order-page .title-type {
    font-size: 0.8125rem;
    padding-right: 0.3125rem;
  }

  .pending-order-page .ordertable2 .set-w-icons {
    width: 8rem;
  }

  .pending-order-page .ordertable2 .sm-t {
    font-size: 0.8125rem;
  }

  .col_03 b {
    font-size: 0.8125rem;
    padding-left: 1.875rem;
  }

  .earning {
    font-size: 0.875rem;
    padding-right: 0;
  }

  .col_05 {
    padding: 0;
  }

  .col_02 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .ordertable2 .arr-right {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .ordertable2 .lab-td {
    font-size: 0.6875rem;
  }

  .collaps-order-d .col-auto {
    padding-left: 0.625rem;
  }

  .Champions-img h4 {
    font-size: 0.8125rem;
  }

  .Champions-img img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .collaps-order-bottom .earnings-val {
    font-size: 0.875rem;
  }

  .collaps-order-bottom .earnings-val span {
    font-size: 1.5rem;
  }

  .collaps-order-bottom .order-id {
    font-size: 0.8125rem;
  }

  .collaps-order-d {
    font-size: 0.75rem;
  }

  .collaps-order-bottom .time {
    font-size: 0.75rem;
  }

  .pending-order-page .ordertable2 .collaps-order-d .icon-s {
    width: 2.375rem;
    height: 2.375rem;
    margin-right: 0.5rem;
  }

  /* .pending-order-page .row-title {
    font-size: 0.8125rem;
  } */

  .font-s {
    font-size: 0.75rem;
    line-height: normal;
  }

  .pending-order-page h3 {
    font-size: 1rem;
  }

  .col_03 {
    width: 12%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .pending-order-page .row-title,
  .hidd-sm {
    display: none;
  }

  .col_01 {
    width: 28%;
  }

  .col_02 {
    width: 30%;
  }

  .col_03 {
    width: 19%;
    padding: 0 0.625rem;
  }

  .col_05 {
    padding: 0 1.25rem;
    width: 50%;
    padding-top: 0.625rem;
  }

  .col_06 {
    width: 47%;
    text-align: right;
    padding-top: 0.625rem;
  }

  .pending-order-page .ordertable2 .accordion-button {
    margin-top: -3.4375rem;
    margin-left: 0.625rem;
    margin-right: -0.625rem;
  }

  .order-t-po {
    float: left !important;
    padding-top: 0.9375rem;
  }
}

@media (max-width: 480px) {
  .pending-order-page .border-start {
    padding-left: 0;
    padding-bottom: 0.1875rem;
    margin-left: 0;
    width: 100%;
    border: none !important;
  }

  .pending-order-page .btn-group > .btn:nth-child(n + 3),
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    margin-bottom: 0.5rem;
  }

  .pending-order-page .ordertable2 .set-w-icons {
    width: 8.4375rem;
  }

  .col_01 {
    width: 38%;
    padding-bottom: 0.625rem;
  }

  .col_02 {
    width: 33%;
    padding-bottom: 0.625rem;
  }

  .col_03 {
    width: 20%;
  }

  .col_04 {
    width: 22%;
  }

  .col_05 {
    padding: 0 0.625rem;
    width: 38%;
  }

  .col_06 {
    width: 37%;
  }

  .collaps-order-bottom .order-id {
    font-size: 0.6875rem;
    padding: 0.4375rem 0.625rem;
  }

  .btn-claim {
    /* margin-top: 0.9375rem !important; */
    width: 100%;
    display: block;
  }

  .sm-w-s {
    flex: auto;
    width: 100%;
    padding-bottom: 0.9375rem;
  }

  .right-side-detail h1,
  .earn-ord-d span {
    font-size: 1.625rem;
  }

  .ac-links {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 0.625rem;
  }

  .ac-links .mr-2 {
    margin-left: 0;
  }
}

@media (max-width: 425px) {
  .col_01 {
    width: 45%;
  }

  .col_02 {
    width: 50%;
    padding-bottom: 0.625rem;
  }

  .col_03 {
    width: 26%;
  }

  .col_05 {
    padding: 0 0.625rem;
    width: 47%;
  }

  .col_06 {
    width: 100%;
    text-align: left;
  }

  .pending-order-page .ordertable2 .accordion-button {
    padding: 0.125rem 0.75rem;
    margin: 0;
  }

  .collaps-order-d .col-auto {
    padding-bottom: 0.625rem;
  }

  .Champions-img img {
    width: 2.25rem;
    height: 2.25rem;
  }
}

@media (max-width: 375px) {
  .col_03 {
    width: 29%;
  }

  .col_04 {
    width: 40%;
  }

  .col_05 {
    padding-left: 1.25rem;
    width: 53%;
    padding-top: 0.625rem;
  }

  .col_06 {
    width: 43%;
    text-align: left;
  }

  .collaps-order-bottom .time {
    font-size: 0.6875rem;
  }

  .collaps-order-bottom .earnings-val {
    font-size: 0.8125rem;
  }

  .collaps-order-bottom .earnings-val span {
    font-size: 1.25rem;
  }

  .collaps-order-bottom {
    background-color: #23223b;
    margin: 0.9375rem 0 0 0;
    padding: 0.625rem 0rem;
  }

  .collaps-order-bottom .order-id {
    margin-top: 0.625rem;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .col_02 {
    width: 100%;
    padding-bottom: 0.625rem;
  }

  .col_03 {
    width: 56%;
  }

  .col_05 {
    width: 100%;
  }

  .col_06 {
    width: 100%;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .desktop-style {
    border-right: 1px solid rgba(193, 193, 193, 0.5);
    padding-right: 20px;
  }
}
